<template>
  <div class="flex flex-col-reverse md:h-full md:flex-row">
    <RadiatorDetailsForm
      class="basis-6/6 md:basis-2/3"
      :node="localRadiator"
      :parent-id="parentId"
      @sync-local-radiator="syncLocalRadiator($event)"
      @update-entity="handleSubmit"
    />
    <RadiatorIdentificationForm
      v-if="
        localRadiator?.radiatorType &&
        localRadiator?.radiatorType !== radiatorTypes.UNKNOWN
      "
      class="basis-6/6 basis-1/3"
      :radiator="localRadiator"
      :is-form-valid="isFormValid"
    />
    <BasePrompt
      :open="showConfirmation"
      :cancel="() => (showConfirmation = false)"
      :close="() => (showConfirmation = false)"
      title="Hinweis"
      :proceed="saveRadiator"
      data-test-id="noIdentifikationConfirmationPrompt"
    >
      <div>Es ist keine Identifizierung ausgewählt.</div>
      <div>Möchtest du den Heizkörper ohne Identifizierung speichern?</div>
    </BasePrompt>
  </div>
  <Teleport v-if="isHeatCostAllocator" to="#modal-footer">
    <PrimaryButton
      label="Weiter"
      :is-disabled="!isFormValid"
      data-testid="button-save"
      class="mr-0"
      @click="saveRadiator"
    />
  </Teleport>
</template>

<script setup lang="ts">
import { ref, computed, provide, onBeforeMount } from 'vue';
import RadiatorDetailsForm from '@/components/Forms/RadiatorDetailsForm.vue';
import { useFormStore } from '@/store/form/formStore';
import { deviceTypeDetails, deviceTypes } from '@/enums/device';
import { radiatorTypes } from '@/enums/radiator/generic';
import { Radiator, RadiatorClasses } from '@/models/radiators/Radiator';
import RadiatorIdentificationForm from '@/components/Forms/RadiatorFormComponents/Identification/RadiatorIdentificationForm.vue';
import { getRadiatorStepsStatus } from '@/components/Forms/Helper/radiatorStepHelper';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import { DeviceType } from '@/models/devices/Device';

const props = defineProps<{
  node: Radiator | undefined;
  parentId: string;
  isHeatCostAllocator?: boolean;
  payload?: any;
}>();

const emit = defineEmits(['update-entity', 'update-entities']);

const formStore = useFormStore();
const isFormValid = computed(() => {
  if (localRadiator.value?.identificationResult) {
    return true;
  } else if (
    localRadiator.value?.radiatorType &&
    localRadiator.value?.radiatorType !== radiatorTypes.PlaceholderRadiator
  ) {
    const isValid = Object.values(getRadiatorStepsStatus(localRadiator.value));
    delete isValid.manufacturer;
    return isValid.every((valid) => valid === true);
  }
  return false;
});

const showResults = ref(false);
const showConfirmation = ref(false);
const localRadiator = ref<RadiatorClasses | undefined>(undefined);
defineExpose({
  handleSubmit,
  isFormValid: isFormValid,
});

const isEditForm = computed(() => !formStore.currentForm?.payload?.deviceType);
const deviceType = computed(() => {
  if (props.isHeatCostAllocator) return deviceTypes.HeatCostAllocator;
  return formStore.currentForm?.payload?.deviceType;
});
const deviceTypeShortLabel = computed(() => {
  return deviceTypeDetails[deviceType.value as DeviceType]?.shortLabel;
});
const ratingsSaveButtonText = computed(() => {
  if (!isEditForm.value) {
    return `+ ${deviceTypeShortLabel.value} hinzufügen`;
  }
  return 'Speichern';
});
const isUnknownRadiator = computed(
  () => localRadiator.value?.radiatorType === radiatorTypes.UNKNOWN
);

function saveRadiator() {
  emit('update-entity', localRadiator.value);
}

function handleSubmit() {
  if (localRadiator.value && placeHolderRadiatorId) {
    localRadiator.value.id = placeHolderRadiatorId;
  }

  if (
    localRadiator.value?.identificationResult === undefined &&
    !isUnknownRadiator.value
  ) {
    showConfirmation.value = true;
    return;
  }
  saveRadiator();
}

function goToListingForm() {
  formStore.setToLastForm();
}

function syncLocalRadiator(radiator) {
  localRadiator.value = radiator;
}

let placeHolderRadiatorId: string | undefined = undefined;
onBeforeMount(() => {
  if (props.node?.radiatorType === radiatorTypes.PlaceholderRadiator) {
    placeHolderRadiatorId = props.node?.id;
    syncLocalRadiator(undefined);
    return;
  }
  syncLocalRadiator(props.node);
});
</script>
