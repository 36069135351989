<template>
  <form class="flex flex-col gap-2" @submit.prevent>
    <h2 class="mt-0 text-center">Willkommen</h2>
    <AuthenticationMessage :user-auth="authenticationMessage" />
    <fieldset class="flex flex-col gap-4">
      <BaseInput
        label="Benutzername"
        placeholder="Benutzername"
        :validation="v$?.user.username"
        test-id="username"
        :model-value="user.username"
        autocomplete="username"
        @update:model-value="
          (newValue) => (user.username = newValue.toLowerCase())
        "
      />

      <BaseInput
        v-model="user.password"
        type="password"
        autocomplete="current-password"
        test-id="password"
        label="Passwort"
        placeholder="Passwort"
        :validation="v$.user.password"
      />
    </fieldset>
    <div class="flex flex-row justify-end">
      <TertiaryButton
        class="pr-0 hover:bg-transparent"
        data-testid="forget-password-button"
        label="Passwort vergessen?"
        @click="forgetPassword()"
      />
    </div>

    <PrimaryButton
      type="submit"
      label="Anmelden"
      data-testid="login-button"
      :class="['w-full', { 'is-loading': isLoading }]"
      :disabled="isSubmitDisabled"
      @click="login()"
    >
    </PrimaryButton>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import AuthenticationMessage from '@/components/Login/AuthenticationMessage.vue';
import { Validation } from '@vuelidate/core';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';

export default defineComponent({
  name: 'LoginForm',
  components: {
    TertiaryButton,
    PrimaryButton,
    BaseInput,
    AuthenticationMessage,
  },
  emits: ['update:user', 'login', 'forget-password'],
  setup(_props, { emit }) {
    const user = inject('user');
    const authenticationMessage = inject('authenticationMessage');
    const v$ = inject<Ref<Validation>>('v$');
    const isLoading = inject<boolean>('isLoading');

    const login = () => {
      emit('login');
    };

    const forgetPassword = () => {
      emit('forget-password');
    };

    const isSubmitDisabled = computed(() => {
      return v$?.value.$validationGroups?.login.$invalid;
    });

    return {
      v$,
      user,
      login,
      isLoading,
      isSubmitDisabled,
      authenticationMessage,
      forgetPassword,
    };
  },
});
</script>
