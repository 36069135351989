<template>
  <div class="float-label-input relative">
    <input
      ref="input"
      :type="type"
      placeholder=" "
      :value="modelValue"
      :class="{ 'is-danger': isError }"
      :pattern="pattern"
      :inputmode="inputmode"
      :disabled="disabled"
      :data-testid="testId"
      :autocomplete="autocomplete"
      class="focus:shadow-outline m-0 block w-full appearance-none rounded-md border border-zinc-300 bg-white px-3 py-2.5 leading-normal focus:border-primary-2 focus:outline-none dark:border-dark-3 dark:bg-transparent"
      @input="updateInput"
    />
    <label
      for="input"
      class="div-with-line pointer-events-none absolute left-0 top-2.5 px-3.5 transition duration-200 ease-in-out"
    >
      <span>{{ placeholderResolved }}</span>
    </label>
    <div
      v-if="type === 'password'"
      class="text-blue-gray-500 absolute right-3 top-2/4 grid h-5 w-5 -translate-y-2/4 place-items-center"
      @click="revealPassword"
    >
      <span class="mdi mdi-eye-outline" />
    </div>
    <div v-if="isError" class="validation-error">
      <span v-for="error in validation.$silentErrors" :key="error">
        {{ error.$message }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, HTMLAttributes, PropType } from 'vue';

export default defineComponent({
  name: 'BaseInput',
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String as PropType<'text' | 'number' | 'password'>,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    pattern: {
      type: String,
      default: null,
    },
    inputmode: {
      type: String as PropType<HTMLAttributes['inputmode']>,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    whenValidText: {
      type: String,
      default: 'Eingabe ungültig',
    },
    testId: {
      type: String,
      default: 'test-id-input',
    },
    validation: {
      type: Object,
      default: undefined,
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      defaultValue: {
        text: '',
        number: null,
      },
    };
  },
  computed: {
    placeholderResolved() {
      if (this.placeholder) {
        return this.placeholder;
      }
      return this.label;
    },
    isRequired() {
      if (this.validation) {
        return !this.validation?.required?.$params?.optional;
      }
      return true;
    },
    isError() {
      return this.validation?.$errors?.length > 0 && this.validation?.$dirty;
    },
    isValid() {
      if (!this.validation) {
        return true;
      }
      return this.validation.$errors?.length === 0;
    },
  },
  methods: {
    updateInput(event: Event) {
      const element = event.target as HTMLInputElement;
      if (
        element.value === '' &&
        typeof this.defaultValue[this.type] !== 'undefined'
      ) {
        this.$emit('update:modelValue', this.defaultValue[this.type]);
        return;
      }
      this.$emit('update:modelValue', element.value.trim());
    },
    revealPassword(event: Event) {
      const input = this.$refs.input as any;
      const eyeIcon = event.target as any;

      if (input.type === 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }

      if (input.type === 'text') {
        eyeIcon.style.color = 'black';
      } else {
        eyeIcon.style.color = 'hsl(0deg, 0%, 86%)';
      }
    },
  },
});
</script>
<style lang="scss">
.invalid-notification {
  font-size: small;
  color: #ff335c;
}

.float-label-input {
}

.float-label-input:focus-within label,
.float-label-input input:placeholder-shown + label {
  left: 20px;
}

.float-label-input:focus-within label,
.float-label-input input:not(:placeholder-shown) + label {
  transform: translateY(-1.5rem) translateX(0.2rem) scaleY(0.75) scaleX(0.75);
  transform-origin: 0;
}

.div-with-line {
  position: relative;
  z-index: 1;
}

.div-with-line::before {
  @apply border-t-4 border-white dark:border-dark-1;
  position: absolute;
  left: 8px;
  right: 8px;
  top: 0.73rem;
  content: '';
  z-index: -1;
}
</style>
