<template>
  <AccordionCard
    v-for="(roomgroups, id) in rooms"
    :key="id"
    :text="resolveRoomGroupName(id)"
    :header-icon="'mdi-content-duplicate'"
    :icon="getRoomGroupStatus(id)"
    class="mb-5"
    data-testId="roomGroupCard"
  >
    <div
      v-for="(roomsInRoomGroup, floor) in roomgroups"
      :key="floor"
      class="mb-5 ml-2 mt-5"
    >
      <div class="has-text-weight-bold mb-2">{{ floor }}</div>
      <div class="flex flex-wrap">
        <AccordionCard
          v-for="device in roomsInRoomGroup"
          :key="device"
          :text="device.getLabel()"
          :header-icon="'mdi-cellphone'"
          :icon="getDeviceStatus(device)"
          data-testId="deviceCard"
        >
          <ul class="device-info-container">
            <li>
              <span class="has-text-weight-medium"
                >Vorerfassender Zähler:&nbsp;
              </span>
              <em
                v-if="device.isAdvancedRegistrationMeter"
                class="mdi mdi-check-bold"
              ></em>
              <em
                v-if="!device.isAdvancedRegistrationMeter"
                class="mdi mdi-times"
              ></em>
            </li>
            <li>
              <span class="has-text-weight-medium">Seriennummer:&nbsp;</span
              >{{ device.serialNumber }}
            </li>
            <li>
              <span class="has-text-weight-medium">Raum:&nbsp;</span
              >{{ getDeviceProperties(device.id).room }}
            </li>
            <li>
              <span class="has-text-weight-medium">Etage:&nbsp;</span
              >{{ getDeviceProperties(device.id).floorType }}
            </li>
          </ul>
        </AccordionCard>
      </div>
    </div>
  </AccordionCard>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import AccordionCard from '../UI/Accordion/AccordionCard.vue';
import { deviceTypes } from '@/enums/device';
import { devicefunkcheckStatus } from '@/enums/funkcheck';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { DirectMeterGateway } from '@/models/devices';
import { DeviceClasses } from '@/models/devices/Device';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';

const entitiesStore = useEntitiesStore();
const deviceStore = useDeviceStore();

const loading = ref(false);
const isOpened = ref(undefined);
const deviceMap = ref(new Map());

const rooms = computed(() => {
  const devices = useInstallationPointStore().getAllActiveDevices();
  const rooms: any = {};
  const whitelist: {
    gateways: DirectMeterGateway[];
    devices: DeviceClasses[];
  } = {
    gateways: [],
    devices: [],
  };
  devices.forEach((device) => {
    if (device instanceof DirectMeterGateway) {
      return;
    }

    const parentId = device.getParentId();
    if (!parentId) {
      throw new Error('Device has no parent');
    }
    const parent = entitiesStore.getEntityById(parentId);
    const parentRoom = entitiesStore.getEntityById(parent.roomId);
    deviceMap.value.set(device.id, {
      roomGroupId: parent.billedInRoomGroupId,
      floorType: parentRoom.getFloor(),
      room: parentRoom.usageType,
      status: device.funkcheckStatus,
    });

    const roomgroupLabel = deviceMap.value.get(device.id).roomGroupId;
    const floorType = deviceMap.value.get(device.id).floorType;
    if (!rooms[roomgroupLabel]) {
      rooms[roomgroupLabel] = {};
    }
    if (!rooms[roomgroupLabel][floorType]) {
      rooms[roomgroupLabel][floorType] = [];
    }
    rooms[roomgroupLabel][floorType].push(device);
    if (device.deviceType === deviceTypes.DirectMeterGateway) {
      whitelist.gateways.push(device);
    } else {
      whitelist.devices.push(device);
    }
  });
  return rooms;
});

function getDeviceStatus(device: DeviceClasses) {
  if (!device.isAutomatedMeterReading) {
    return 'mdi mdi-numeric-off';
  }
  if (device.funkcheckStatus === devicefunkcheckStatus.error) {
    return 'mdi mdi-alert-circle-outline error';
  }
  if (device.funkcheckStatus === devicefunkcheckStatus.done) {
    return 'mdi mdi-check-circle-outline success';
  }
}

function resolveRoomGroupName(id: string) {
  const roomgroup = entitiesStore.getEntityById(id);
  return roomgroup.getLabel();
}

function getDeviceProperties(deviceId: string) {
  return deviceMap.value.get(deviceId);
}

function getRoomGroupStatus(id: string) {
  for (const deviceId of Array.from(deviceMap.value.keys())) {
    if (deviceMap.value.get(deviceId)?.roomGroupId !== id) {
      continue;
    }
    if (deviceMap.value.get(deviceId).status === devicefunkcheckStatus.error) {
      return 'mdi-alert-circle-outline error';
    }
    if (!deviceMap.value.get(deviceId).status) {
      return undefined;
    }
  }
  return 'mdi-alert-circle-outline success';
}
</script>
