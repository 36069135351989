import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { Radiator } from '@/models/radiators/Radiator';
import {
  fetchRadiatorIdentificationPictures,
  getRadiatorRatings,
} from '@/api/radiator/RadiatorRating';
import { entityTypes } from '@/enums/generic';
import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';

export function useRadiatorStore() {
  return new RadiatorStore();
}

@Store
export class RadiatorStore extends miniEntityStore {
  private _radiators: Map<string, Radiator>;

  get radiators(): Map<string, Radiator> {
    return this._radiators;
  }

  constructor() {
    super();
    this._radiators = new Map<string, Radiator>();
  }

  addEntities(entities: Radiator[]): void {
    this._radiators = new Map(
      entities.map((entity) => [entity.id, entity] as [string, Radiator])
    );
  }

  addEntity(newRadiator: Radiator) {
    this._radiators.set(<string>newRadiator.id, newRadiator);
  }

  resetState() {
    this._radiators = new Map();
  }

  /*
   * Getters
   */
  getAllRadiatorsInRoom(roomId: string): Array<Radiator> {
    return getAllEntitiesByCriteria(
      this._radiators,
      (radiator: Radiator) => radiator.parentRoomId === roomId
    );
  }

  getRadiatorByInstallationPoint(installationPoint: InstallationPoint) {
    if (!installationPoint?.radiatorId) {
      return;
    }
    return useRadiatorStore().radiators.get(installationPoint.radiatorId);
  }

  getRadiatorTypeByInstallationPoint(installationPoint: InstallationPoint) {
    return this.getRadiatorByInstallationPoint(installationPoint)?.radiatorType;
  }

  // see getAllRadiatorsInRoom
  getAllRadiators(radiator: Radiator) {
    if (radiator.parentRoomId) {
      return this.getAllRadiatorsInRoom(radiator.parentRoomId);
    }
  }

  getNewRadiatorOrdinal(parentId: string) {
    const children = getAllEntitiesByCriteria(
      this._radiators,
      (node: Radiator) => node.getParentId() === parentId
    );
    return children.length + 1;
  }

  deleteEntity(entity: Radiator) {
    this._radiators.delete(<string>entity.id);
  }

  /*
   * Actions
   */
  fetchRadiatorRatings(radiator: Radiator) {
    return getRadiatorRatings(radiator)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  fetchRadiatorIdentPictures(identificationPrefix: string) {
    return fetchRadiatorIdentificationPictures(identificationPrefix)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  swapRadiator(radiator: Radiator) {
    if (radiator.type !== entityTypes.radiator) {
      throw new Error('Invoked SWAP_RADIATOR: entity is not a radiator');
    }
    const entitiesStore = useEntitiesStore();
    const siblings = entitiesStore.getSiblings(radiator);

    const sibling = siblings.find(
      (e: Radiator) => e.ordinal === radiator.ordinal + 1
    );

    if (sibling) {
      const tempOrdinal = sibling.ordinal;
      sibling.ordinal = radiator.ordinal;
      radiator.ordinal = tempOrdinal;
    }
    entitiesStore.saveEntity(radiator);
  }
}
