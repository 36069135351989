import { RadiatorDevice } from '@/models/types/Entity';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { SmartRadiatorThermostat } from '@/models/devices';
import { deviceTypes } from '@/enums/device';
import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { DeviceClasses, LegacyPlantMeterDevice } from '@/models/devices/Device';
import { Device } from '@/models/devices/Device';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';

export function useDeviceStore() {
  return new DeviceStore();
}

@Store
export class DeviceStore extends miniEntityStore {
  private _devices: Map<string, DeviceClasses>;
  private _oldDevices: Set<string>;

  constructor() {
    super();
    this._devices = new Map();
    this._oldDevices = new Set();
  }

  get devices(): Map<string, DeviceClasses> {
    return this._devices;
  }

  get getSRTs() {
    return getAllEntitiesByCriteria(
      this._devices,
      (entity: DeviceClasses) =>
        entity.deviceType === deviceTypes.SmartRadiatorThermostat
    );
  }

  get getLoRaWanGateways() {
    return getAllEntitiesByCriteria(
      this._devices,
      (entity: DeviceClasses) =>
        entity.deviceType === deviceTypes.LoRaWanGateway
    );
  }

  getDevices(): Map<string, DeviceClasses> {
    const devices = new Map(this._devices);
    this._oldDevices.forEach((oldDeviceId: string) => {
      devices.delete(oldDeviceId);
    });
    return devices;
  }

  get uniqueBatteryPackSerialNumbers() {
    const batteryPackSerialNumbers = new Map();
    this._devices.forEach((device) => {
      if (
        device instanceof SmartRadiatorThermostat &&
        device.serialNumberBatteryPack
      ) {
        const serial = device.serialNumberBatteryPack;
        if (!batteryPackSerialNumbers.has(serial)) {
          batteryPackSerialNumbers.set(serial, []);
        }
        batteryPackSerialNumbers.get(serial).push(device);
      }
    });
    return batteryPackSerialNumbers;
  }

  get uniqueDeviceSerialNumbers() {
    const serialNumbers = new Map();
    this._devices.forEach((device) => {
      const key = this.createUniqueDeviceKey(device);
      if (!serialNumbers.has(key)) {
        serialNumbers.set(key, []);
      }
      serialNumbers.get(key).push(device);
    });
    return serialNumbers;
  }

  createUniqueDeviceKey(device: Device<any>) {
    return `${device.serialNumber}${device.deviceType}`;
  }

  isDeviceDuplicated(newDevice: Device<any>): boolean {
    const devicesWithSameKey = this.uniqueDeviceSerialNumbers.get(
      this.createUniqueDeviceKey(newDevice)
    );
    if (!devicesWithSameKey) {
      return false;
    }
    return devicesWithSameKey.some(
      (existingDevice: Device<any>) => existingDevice.id !== newDevice.id
    );
  }

  isBatteryPackDuplicated(
    batteryPackSerialNumber: string,
    device: Device<any>
  ): boolean {
    const devicesWithSameSerial = this.uniqueBatteryPackSerialNumbers.get(
      batteryPackSerialNumber
    );
    if (!devicesWithSameSerial) {
      return false;
    }
    return devicesWithSameSerial.some(
      (existingDevice: Device<any>) => existingDevice.id !== device.id
    );
  }

  resetState() {
    this._devices = new Map();
  }

  getDirectMeterGateway() {
    const directMeterGateway = getAllEntitiesByCriteria(
      this._devices,
      (entity: DeviceClasses) =>
        entity.deviceType === deviceTypes.DirectMeterGateway
    );
    return directMeterGateway[0] ? directMeterGateway[0] : undefined;
  }

  addEntities(entities: DeviceClasses[]): void {
    this._devices = new Map(
      entities.map((entity) => [entity.id, entity] as [string, DeviceClasses])
    );
  }

  addEntity(newDevice: DeviceClasses): void {
    this._devices.set(newDevice.id, newDevice);
  }

  getAllDevicesInRoom(roomId: string): Array<DeviceClasses> {
    const installationPointsInRoom: InstallationPoint[] = [];

    installationPointsInRoom.push(
      ...useInstallationPointStore().getInstallationPointsByParentId(roomId)
    );

    const uniqueInstallationPoints = installationPointsInRoom.filter(
      (object, index, self) =>
        index === self.findIndex((o) => o.id === object.id)
    );

    return Array.from(uniqueInstallationPoints)
      .map(
        (installationPoint) =>
          installationPoint.activeDeviceId &&
          this.devices.get(installationPoint.activeDeviceId)
      )
      .filter(Boolean) as DeviceClasses[];
  }

  deleteEntity(entity: DeviceClasses) {
    this._devices.delete(<string>entity.id);
  }

  getDevicesOnRadiator(
    radiatorId: string,
    _deviceType?: string
  ): Array<RadiatorDevice> {
    const installationPointsOnRadiator =
      useInstallationPointStore().getInstallationPointsByRadiatorId(radiatorId);

    return installationPointsOnRadiator
      .map(
        (installationPoint) =>
          installationPoint.activeDeviceId &&
          installationPoint.supportedDeviceType === _deviceType &&
          this.devices.get(installationPoint.activeDeviceId)
      )
      .filter(Boolean) as RadiatorDevice[];
  }

  getDevicesOnPlantByType(
    plantId: string,
    _deviceType?: string
  ): Array<LegacyPlantMeterDevice> {
    const installationPointsOnPlant =
      useInstallationPointStore().getInstallationPointsOnPlant(plantId);

    return installationPointsOnPlant
      .map(
        (installationPoint) =>
          installationPoint.activeDeviceId &&
          installationPoint.supportedDeviceType === _deviceType &&
          this.devices.get(installationPoint.activeDeviceId)
      )
      .filter(Boolean) as LegacyPlantMeterDevice[];
  }

  getDevicesOnPlant(plantId: string): LegacyPlantMeterDevice[] {
    const heatingPlantDevicesOnPlant = this.getDevicesOnPlantByType(
      plantId,
      deviceTypes.LegacyHeatingPlantMeter
    );
    const waterPlantDevicesOnPlant = this.getDevicesOnPlantByType(
      plantId,
      deviceTypes.LegacyWaterPlantMeter
    );
    return [...heatingPlantDevicesOnPlant, ...waterPlantDevicesOnPlant];
  }

  getDevicesByInstallationPointId(
    installationPointId: string
  ): Array<DeviceClasses> {
    return getAllEntitiesByCriteria(
      this._devices,
      (entity: DeviceClasses) =>
        entity.installationPointId === installationPointId
    );
  }

  setDeviceFunkcheckStatus(deviceId: string, status: string) {
    const device = this._devices.get(deviceId);
    if (!device) {
      console.warn('DeviceClasses not found');
      return;
    }
    device.funkcheckStatus = status;
  }
}
