import { Order, OrderType } from '@/models/Order';
import { OrderStatus } from '@/models/types/OrderStatus';
import {
  fetchAllAssignedOrders,
  putOrderDetails,
  saveOrderStatus,
} from '@/api/order/OrderManagement';
import { Pinia, Store } from '@/store/pinia-class-component';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Roomgroup } from '@/models';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import {
  EntityTransaction,
  EntityTransactionType,
  OrderChanges,
  TransactionDiff,
} from '@/models/OrderChanges';
import { Entity } from '@/models/types/Entity';
import { generateDiff } from '@/utils/diff/diff';
import { DifferenceChange } from 'microdiff';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';

export function useOrderStore() {
  return new OrderStore();
}

@Store
export class OrderStore extends Pinia {
  private _orders: Array<Order>;
  private _activeOrder: Order | undefined;
  private _orderType?: OrderType;

  constructor() {
    super();
    this._orders = [];
    this._activeOrder = undefined;
    this._orderType = undefined;
  }

  get orderType() {
    return this._orderType;
  }

  get hasUnsavedOrderChanges() {
    const orderChanges = this.getOrderChanges();
    return orderChanges?.currentHash !== orderChanges?.lastSavedHash;
  }

  setOrderType(type?: OrderType) {
    this._orderType = type;
  }

  isOnSiteInspection() {
    return this.orderType === OrderType.ON_SITE_INSPECTION;
  }

  get orders(): Array<Order> {
    return this._orders.sort((a: Order, b: Order) => {
      if (a.appointment.start && b.appointment.start) {
        return (
          Date.parse(a.appointment.start) - Date.parse(b.appointment.start)
        );
      }
      return 0;
    });
  }

  get activeOrderId(): string | undefined {
    return this._activeOrder?.orderId;
  }

  get activeOrder(): Order | undefined {
    return this._activeOrder;
  }

  get pendingOrders() {
    return this.orders.filter(
      (order: Order) => order.status === OrderStatus.OPEN
    );
  }

  get activeOrders() {
    return this.orders.filter(
      (order: Order) => order.status === OrderStatus.IN_PROGRESS
    );
  }

  get closedOrders() {
    return this.orders.filter(
      (order: Order) => order.status === OrderStatus.CLOSED
    );
  }

  get selectedOrder() {
    return this._activeOrder;
  }

  resetInstallerNote() {
    if (!this._activeOrder) return;
    this._activeOrder.installerNote = '';
  }

  getOrderById(orderId: string) {
    return this._orders.find((order: Order) => order.id === orderId);
  }

  async fetchAllOrders() {
    this._orders = await fetchAllAssignedOrders();
  }

  clearOrders() {
    this._orders = [];
    this._activeOrder = undefined;
  }

  async startOrder(id: string) {
    const order = this._orders.find(
      (orderElement: Order) => orderElement.id === id
    );

    if (!order) {
      console.error(`Can't find order with id: ${id}`);
      return;
    }

    const activeOrder = await usePersistenceStore().getActiveOrder(order.id);

    if (!activeOrder) {
      usePersistenceStore().setActiveOrder(order);
    }

    const mapPlainDiffsToClass = (diffs: TransactionDiff[] | undefined) =>
      diffs?.map(
        (diff) =>
          new TransactionDiff(
            diff?.oldValue,
            diff?.newValue,
            diff?.path?.split('.') ?? [],
            diff?.type
          )
      ) ?? [];

    const mapPlainTransactionsToClass = (transactions: Record<string, any>) =>
      Object.entries(transactions).reduce(
        (mappedTransactions, [key, value]) => {
          const diff = mapPlainDiffsToClass(value?.diff);
          mappedTransactions[key] = new EntityTransaction(
            value?.entity,
            value?.entityType,
            value?.entityId,
            value?.type,
            value?.timestamp,
            diff
          );
          return mappedTransactions;
        },
        {} as Record<string, EntityTransaction>
      );

    this._activeOrder = order;
    this._activeOrder.changes = plainToInstance(
      OrderChanges,
      activeOrder?.changes ?? order?.changes ?? {}
    );
    this._activeOrder.changes.transactions = mapPlainTransactionsToClass(
      this._activeOrder.changes.transactions
    );

    this.setOrderType(order.workTypeCode);

    if (order.status !== OrderStatus.IN_PROGRESS) {
      try {
        await saveOrderStatus(id, OrderStatus.IN_PROGRESS);
        order.status = OrderStatus.IN_PROGRESS;
      } catch (e) {
        console.error(e);
      }
    }
  }

  clearActiveOrderInfo() {
    if (this._activeOrder) {
      const businessEntityId = this._activeOrder.businessEntityId;
      useEntitiesStore().removeBusinessEntityFromList(businessEntityId);
      usePersistenceStore().clearLocalOrder(
        businessEntityId,
        this._activeOrder.id
      );

      const order = this._orders.find(
        (order) => order.id === this._activeOrder?.id
      );
      if (order) {
        order.status = OrderStatus.CLOSED;
      }
    }
    this._activeOrder = undefined;
  }

  finishOrder() {
    if (!this._activeOrder) {
      console.error(`Can't find order ${this._activeOrder}`);
      return;
    }
    const orderId = this._activeOrder.id;
    putOrderDetails(
      orderId,
      this.activeOrder?.installerNote ? this._activeOrder.installerNote : ''
    ).catch((error) => {
      console.error('Failed to put order details:', error);
    });

    return saveOrderStatus(orderId, OrderStatus.CLOSED)
      .then(() => {
        this.clearActiveOrderInfo();
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  toggleRoomGroupProgress(roomGroup: Roomgroup) {
    this.changeRoomGroupProgress(roomGroup);
  }

  isRoomGroupCompleted(roomGroup: Roomgroup) {
    return this._activeOrder?.changes.hasRoomGroupProgress(roomGroup.id);
  }

  changeRoomGroupProgress(roomGroup: Roomgroup) {
    if (!this._activeOrder) {
      return;
    }

    if (this._activeOrder?.changes.hasRoomGroupProgress(roomGroup.id)) {
      this._activeOrder?.changes.removeRoomGroupProgress(roomGroup.id);
    } else {
      this._activeOrder?.changes.addRoomGroupProgress(roomGroup.id);
    }

    usePersistenceStore().updateOrderChanges(
      this._activeOrder.id,
      this._activeOrder.changes
    );
  }

  handleSaveEntityTransactions(entity: Entity) {
    if (!this._activeOrder) {
      return;
    }

    const changes: OrderChanges = this._activeOrder.changes;
    if (
      !useEntitiesStore().hasEntityById(entity.id) &&
      !changes.transactions[entity.id]
    ) {
      this._activeOrder.changes.upsertTransaction(
        entity,
        EntityTransactionType.CREATE_ENTITY
      );
    } else {
      const oldEntity = useEntitiesStore().getEntityById(entity.id);
      const oldEntityCloned = instanceToInstance(oldEntity);
      const newEntityCloned = instanceToInstance(entity);

      const currentDiff = generateDiff(oldEntityCloned, newEntityCloned);

      if (currentDiff.length === 0) return;
      if (!changes.transactions[entity.id]) {
        this._activeOrder.changes.upsertTransaction(
          entity,
          EntityTransactionType.UPDATE_ENTITY,
          currentDiff as DifferenceChange[]
        );
      } else {
        this._activeOrder.changes.upsertTransaction(
          entity,
          changes.transactions[entity.id].type,
          currentDiff as DifferenceChange[]
        );
      }
    }
    this.updateOrderChangesY().then(() =>
      console.debug('Order save changes updated')
    );
  }

  handleDeleteEntityTransactions(entity: Entity) {
    if (!this._activeOrder) {
      return;
    }

    const changes = this._activeOrder.changes;

    const isEntityAlreadyInTransaction = !!changes.transactions[entity.id];
    const newEntityInTransaction = useEntitiesStore().hasEntityById(entity.id);

    if (isEntityAlreadyInTransaction) {
      delete changes.transactions[entity.id];
    } else if (newEntityInTransaction) {
      this._activeOrder.changes.upsertTransaction(
        entity,
        EntityTransactionType.DELETE_ENTITY
      );
    }
    this.updateOrderChangesY().then(() =>
      console.debug('Order changes updated')
    );
  }

  async updateOrderChangesY() {
    if (!this._activeOrder) {
      throw new Error('No active order found');
    }

    return this._activeOrder.changes.onOrderChangesUpdate().then(() => {
      if (!this._activeOrder) return;
      usePersistenceStore().updateOrderChanges(
        this._activeOrder.id,
        this._activeOrder.changes
      );
    });
  }

  updateLastSavedOnActiveOrder() {
    const activeOrder = this._activeOrder;
    if (!activeOrder) return;

    return activeOrder.changes.onOrderChangesUpdate().then(() => {
      activeOrder.changes.onSaveOrderChanges();

      usePersistenceStore().updateOrderChanges(
        activeOrder.id,
        activeOrder.changes
      );
    });
  }

  getOrderChanges() {
    if (this._activeOrder) {
      return this._activeOrder.changes;
    }
    return undefined;
  }

  getOrderAddresses() {
    const addresses = [];
    if (this._activeOrder?.primaryAddress) {
      addresses.push(this._activeOrder?.primaryAddress);
    }
    addresses.push(...(this._activeOrder?.additionalAddresses ?? []));
    return addresses;
  }
}
