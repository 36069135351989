import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { InspectedBuilding } from '@/models/InspectedBuilding';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { AVERAGE_SRT_PER_ROOM_GROUP } from '@/enums/roomgroup';
import { Roomgroup } from '@/models';

export function useInspectedBuildingStore() {
  return new InspectedBuildingStore();
}

@Store
export class InspectedBuildingStore extends miniEntityStore {
  private _inspectedBuildings: Map<string, InspectedBuilding>;

  constructor() {
    super();
    this._inspectedBuildings = new Map();
  }

  get inspectedBuildings(): Map<string, InspectedBuilding> {
    return this._inspectedBuildings;
  }

  addEntities(entities: InspectedBuilding[]): void {
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      this._inspectedBuildings.set(entity.id, entity);
    }
  }

  addEntity(inspectedBuilding: InspectedBuilding): void {
    this._inspectedBuildings.set(
      <string>inspectedBuilding.id,
      inspectedBuilding
    );
  }

  deleteEntity(entity: InspectedBuilding): void {
    this._inspectedBuildings.delete(entity.id);
  }

  resetState() {
    this._inspectedBuildings = new Map();
  }

  getCurrentInspectedBuilding() {
    const activeBuildingId = useBuildingStore().getCurrentBuilding()?.id;
    if (activeBuildingId)
      return this.getInspectedBuildingByBuildingId(activeBuildingId);
  }

  getInspectedBuildingByBuildingId(buildingId: string) {
    return Array.from(this._inspectedBuildings.values()).find(
      (inspectedBuilding) => inspectedBuilding.buildingId === buildingId
    );
  }

  getSystemSRTs(buildingId: string) {
    const roomGroupsByBuilding: Roomgroup[] =
      useRoomGroupStore().getRoomGroupsByBuildingId(buildingId);
    const nonGeneralRoomGroups: Roomgroup[] = roomGroupsByBuilding.filter(
      (roomGroup) => roomGroup.usageType !== 'GENERAL'
    );
    return Math.round(AVERAGE_SRT_PER_ROOM_GROUP * nonGeneralRoomGroups.length);
  }

  adjustSystemSRTs(buildingId: string) {
    const inspectedBuilding = this.getInspectedBuildingByBuildingId(buildingId);

    if (inspectedBuilding) {
      inspectedBuilding.systemSRTs = this.getSystemSRTs(buildingId);
    }
  }
}
