import { entityTypes } from '@/enums/generic';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';
import { generateUniqueId } from '@/helpers/uuidUtils';
import { AccessoryType, deviceTypes } from '@/enums/device';
import { DeviceType } from '@/models/installationPoint/InstallationPoint';
import { LoRaWanRadioCheck } from '@/api/funkcheck/LoRaWanRadioCheck';
import { DeviceClasses } from '@/models/devices/Device';
import { PlantDataClasses } from '@/models/plant/Plant';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Type } from 'class-transformer';
import { Building } from '@/models';

export class InspectionPoint {
  id: string;
  type = entityTypes.inspectionPoint;
  roomId: string;
  activeTestDeviceId?: string;
  @Type(() => InspectionData)
  inspectionData?: InspectionData;
  accessories: AccessoryType[];
  billedInRoomGroupId: string;
  supportedDeviceType?: DeviceType;
  note?: string;

  constructor(roomId: string) {
    this.id = generateUniqueId();
    this.roomId = roomId;
    this.note = undefined;
    this.accessories = [];
    this.activeTestDeviceId = undefined;
    this.inspectionData = undefined;
    this.billedInRoomGroupId = '';
    this.supportedDeviceType = undefined;
  }

  setSupportedDeviceType(deviceType: DeviceType) {
    this.supportedDeviceType = deviceType;
  }

  addInspectionData(inspectionData: InspectionData) {
    this.inspectionData = inspectionData;
  }

  getActiveDevice(): DeviceClasses | PlantDataClasses {
    if (!this.activeTestDeviceId) throw Error('No active device id');
    return useEntitiesStore().getEntityById(this.activeTestDeviceId);
  }

  unsetActiveDevice() {
    this.activeTestDeviceId = undefined;
  }

  getParentId() {
    return this.roomId;
  }

  getLabel() {
    if (this.supportedDeviceType === deviceTypes.SmartRadiatorThermostat) {
      return 'Test-SRT';
    }
    return 'Gateway';
  }

  getTagColor() {
    if (this.supportedDeviceType === deviceTypes.SmartRadiatorThermostat) {
      return '#7c3aed';
    }
    if (this.supportedDeviceType === deviceTypes.LoRaWanGateway) {
      return '#22c55e';
    }

    return '#5d6dcb';
  }
}

export class InspectionData {
  lteReception?: number;
  lteReceptionDbm?: number;
  isLteDeviceAvailable?: boolean;
  hasMobileLteConnection?: boolean;
  radioCheckResult?: OverallRadioCheckStatus;
  radioChecks: Array<LoRaWanRadioCheck>;
  connectionOptions: Array<string>;
  connectionAvailable: boolean;
  cableLength?: number;

  constructor() {
    this.lteReception = undefined;
    this.lteReceptionDbm = undefined;
    this.isLteDeviceAvailable = true;
    this.hasMobileLteConnection = undefined;
    this.radioCheckResult = undefined;
    this.radioChecks = [];
    this.connectionOptions = [];
    this.connectionAvailable = true;
    this.cableLength = undefined;
  }

  setRadioCheckResult(
    result: LoRaWanRadioCheck,
    runId: number,
    startedAt?: string,
    updatedAt?: string
  ) {
    const radioCheckResult = {
      status: result.status,
      runId,
      startedAt,
      updatedAt,
      result: result.result,
    };
    this.radioCheckResult = result.status;
    this.radioChecks.push(radioCheckResult);
  }
}
