<template>
  <LayoutWrapper>
    <div
      class="prose prose-neutral flex max-w-none flex-col bg-light-0 dark:prose-invert dark:bg-dark-0 md:flex-row"
    >
      <div
        class="h-screen overflow-y-auto bg-light-0 px-8 dark:bg-dark-0 md:grow md:basis-0"
      >
        <OrderOverview v-model:selectedOrder="selectedOrder"></OrderOverview>
      </div>
      <div
        class="fixed bottom-[-10rem] z-10 flex w-full justify-center rounded-3xl bg-light-1 align-middle transition-all dark:bg-dark-1 md:relative md:bottom-0 md:right-0 md:mt-0 md:rounded-none"
        :class="[selectedOrder ? 'h-screen w-full md:w-1/2' : 'h-0  md:w-0']"
      >
        <OrderDetails
          v-if="selectedOrder"
          v-model:selected-order="selectedOrder"
        ></OrderDetails>
      </div>
    </div>
  </LayoutWrapper>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import OrderOverview from '@/components/OrderManagement/OrderOverview.vue';
import OrderDetails from '@/components/OrderManagement/OrderDetails.vue';
import { useAppStore } from '@/store/app/AppStore';
import { WebEvents } from '@/native/plugins/WebEvents';
import { default as envConfig } from '@/config/runtimeConfig';
import { installRelease } from '@/utils/liveUpdater';
import { fetchLatestReleases } from '@/api/mobile/AppInfoApi';
import LayoutWrapper from '@/components/LayoutWrapper.vue';

const selectedOrder = ref(undefined);
const appStore = useAppStore();

onMounted(async () => {
  WebEvents.onLoggedIn();
  appStore.shouldOpenChangelogDialog().then((shouldOpen) => {
    if (shouldOpen) {
      appStore.toggleChangelogDialog();
    }
  });

  if (appStore.isLiveUpdateEnabled()) {
    const payload = appStore.getLiveUpdatePayload();

    if (payload['environments'].includes(envConfig.sentryEnv)) {
      fetchLatestReleases().then((data) => {
        if (data.length > 0) {
          const latestVersion = data[0];
          console.debug('Latest version' + latestVersion);
          installRelease(latestVersion).then(() => () => {
            console.debug('Live Updater Done');
          });
        }
      });
    }
  }
});
</script>
