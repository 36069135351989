<template>
  <div class="status-indicator is-flex">
    <div :class="[{ isComplete: isComplete }, 'mdi mdi-checkbox-marked']"></div>
    <div>{{ label }}</div>
  </div>
</template>
<script>
export default {
  name: 'StatusIndicator',
  props: {
    label: {
      type: String,
      default: undefined,
    },
    isComplete: {
      type: Boolean,
    },
  },
};
</script>
<style scoped lang="scss">
.status-indicator {
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
}

.isComplete {
  color: #14c011;
}
</style>
