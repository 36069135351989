export const devicefunkcheckStatus = Object.freeze({
  error: 'ERROR',
  done: 'FINISHED',
});

export const mobileSignalStrength = Object.freeze([
  {
    name: '',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/funkcheck/mobileSignalStrength/signal1.svg',
      import.meta.url
    ).href,
  },
  {
    name: '',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/funkcheck/mobileSignalStrength/signal2.svg',
      import.meta.url
    ).href,
  },
  {
    name: '',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/funkcheck/mobileSignalStrength/signal3.svg',
      import.meta.url
    ).href,
  },
  {
    name: '',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/funkcheck/mobileSignalStrength/signal4.svg',
      import.meta.url
    ).href,
  },
]);
