<template>
  <div class="checkbox-block">
    <input
      :id="checkboxId"
      :checked="isChecked"
      type="checkbox"
      class="checkbox-effect checkbox-effect-4"
      @change="updateValue($event.target.checked)"
    />
    <label :for="checkboxId">
      <span class="mdi mdi-check-bold"></span>
      {{ label }}
    </label>
  </div>
</template>

<script>
import { generateUniqueId } from '@/models';

export default {
  name: 'BaseCheckbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isChecked: false,
      checkboxId: 'unique-' + generateUniqueId(),
    };
  },
  watch: {
    modelValue: function (isChecked) {
      this.isChecked = isChecked;
    },
  },
  mounted() {
    this.isChecked = this.modelValue;
  },
  methods: {
    updateValue(isChecked) {
      this.isChecked = isChecked;
      this.$emit('update:modelValue', isChecked);
    },
  },
};
</script>

<style lang="scss">
//vars
$border-color: #5a5a5a;
$icon-color-4: #15940b;

//position absolute mixin
@mixin position-absolute($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

//transitions mixin
@mixin transition-mix(
  $property: all,
  $duration: 0.2s,
  $timing: linear,
  $delay: 0s
) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

.checkbox-block {
  display: flex;
}

.checkbox-effect:not(:checked),
.checkbox-effect:checked {
  position: absolute;
  left: -9999px;
}

label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.checkbox-effect-4:not(:checked) + label:before,
.checkbox-effect-4:checked + label:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid $border-color;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 20px;
  @include transition-mix($delay: 0.2s);
}

.checkbox-effect-4:not(:checked) + label .mdi,
.checkbox-effect-4:checked + label .mdi {
  display: flex;
  height: 100%;
  align-items: center;
  @include position-absolute($left: 2px, $top: 0);
  color: $icon-color-4;
  @include transition-mix;
  transform-origin: center center;
  opacity: 0;
  transform: scale(0);
}

.checkbox-effect-4:checked + label .mdi {
  opacity: 1;
  transform: scale(1);
  @include transition-mix(
    $delay: 0.2s,
    $timing: cubic-bezier(0.57, 1.9, 0, 1.52)
  );
}

.checkbox-effect-4:not(:checked) + label:after {
  transform: scale(0);
}

.checkbox-effect-4:checked + label:before {
  transform: scale(0);
  opacity: 0;
  @include transition-mix;
}

.checkbox-effect-4:checked + label:after {
  transform: scale(1);
  opacity: 1;
  @include transition-mix(
    $delay: 0.2s,
    $timing: cubic-bezier(0.57, 1.9, 0, 1.52)
  );
}
</style>
