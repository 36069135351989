<template>
  <transition name="fade" appear>
    <div
      v-if="active"
      class="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center backdrop-blur-[1px] backdrop-brightness-50"
    >
      <div
        class="flex h-full w-full flex-col gap-4 bg-white p-6 transition-all duration-300 ease-in-out dark:bg-dark-1 md:h-[95vh] md:max-w-4xl md:rounded-xl 2xl:h-[90vh]"
      >
        <header
          class="relative shrink-0 text-2xl font-bold dark:border-dark-2 keyboard-open:hidden"
        >
          <slot name="title"></slot>
          <button
            class="absolute right-[-5px] top-[-6px] z-10 ml-auto inline-flex items-center rounded-lg bg-transparent px-2 py-1.5 text-xl text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-dark-4 dark:hover:text-white"
            aria-label="close"
            data-testid="modalCloseBtn"
            @click="closeModal()"
          >
            <em class="mdi mdi-close" />
          </button>
        </header>
        <section class="grow overflow-auto">
          <slot name="default"></slot>
        </section>
        <footer
          v-if="$slots.buttons"
          class="flex justify-end gap-4 keyboard-open:hidden"
        >
          <slot name="buttons"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:active', 'closed'],

  methods: {
    closeModal() {
      this.$emit('update:active', false);
      this.$emit('closed');
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
