<template>
  <div>
    <BaseModal
      :active="isCameraModalOpen"
      @update:active="updateCameraStatus($event)"
      @closed="stopCameraStream()"
    >
      <template #title> Bild aufnehmen </template>
      <div class="flex justify-center">
        <BaseSpinner v-show="isCameraOpen && isLoading" size="is-small" />
        <div v-show="!isLoading" class="video-container">
          <div class="canvas-container">
            <video
              v-show="!isImageCaptured"
              ref="camera"
              autoplay
              class="camera"
            ></video>
            <canvas
              v-show="isImageCaptured"
              ref="canvas"
              class="canvas"
            ></canvas>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div
          v-if="isCameraOpen && !isImageCaptured"
          class="photo-button"
          data-testid="take-photo-button"
          @click="takePhoto"
        >
          <div class="circle"></div>
          <div class="ring"></div>
        </div>
      </div>

      <template #buttons>
        <SecondaryButton
          v-if="isImageCaptured && isCameraOpen"
          :class="!isImageCaptured ? 'is-primary' : ''"
          label="Neues Bild aufnehmen"
          @click="takePhoto"
        />
        <PrimaryButton
          v-if="isImageCaptured && isCameraOpen"
          data-testid="save-photo-button"
          label="Speichern"
          @click="saveImage"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, Ref, computed } from 'vue';
import BaseModal from '@/components/UI/Modal/BaseModal.vue';
import BaseSpinner from '@/components/UI/Timer/BaseSpinner.vue';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import SecondaryButton from '@/components/UI/Button/SecondaryButton.vue';

/*
For Android usage this Modal will be not shown.
We use instead of an Android intent.
 */
export default defineComponent({
  name: 'ImageCaptureModal',
  components: {
    SecondaryButton,
    PrimaryButton,
    BaseSpinner,
    BaseModal,
  },
  props: {
    isCameraOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['save-image', 'update:isCameraOpen'],
  setup(_props, { emit }) {
    const isImageCaptured = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const cameraStream = ref<MediaStream>(new MediaStream());
    const camera = ref(null) as unknown as Ref<HTMLVideoElement>;
    const canvas = ref(null) as unknown as Ref<HTMLCanvasElement>;

    // We don't want to open up the modal in android native
    const isCameraModalOpen = computed(() => {
      return _props.isCameraOpen && !Capacitor.isNativePlatform();
    });

    const updateCameraStatus = (status: boolean) => {
      emit('update:isCameraOpen', status);
    };

    const openNativeCamera = async () => {
      const image = await Camera.getPhoto({
        quality: 80,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
      });

      emit('save-image', `data:image/jpeg;base64,${image.base64String}`);
    };

    const openCamera = () => {
      if (!Capacitor.isNativePlatform()) {
        createCameraElement();
      } else {
        updateCameraStatus(false);
        openNativeCamera();
      }
    };

    const createCameraElement = () => {
      isLoading.value = true;
      updateCameraStatus(true);
      const constraints = ((window as any).constraints = {
        video: {
          facingMode: 'environment',
        },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          isLoading.value = false;
          camera.value.srcObject = stream;
          cameraStream.value = stream;
        })
        .catch(() => {
          isLoading.value = false;
          alert('Camera requires access-permission');
        });
    };

    const stopCameraStream = () => {
      const tracks = cameraStream.value.getTracks();
      tracks.forEach(function (track: { stop: () => void }) {
        track.stop();
      });
    };

    const takePhoto = () => {
      if (!isImageCaptured.value) {
        canvas.value.width = camera.value.videoWidth;
        canvas.value.height = camera.value.videoHeight;
        canvas.value.getContext('2d')?.drawImage(camera.value, 0, 0);
      }
      isImageCaptured.value = !isImageCaptured.value;
    };

    const saveImage = () => {
      const imageToData = canvas.value.toDataURL();
      emit('save-image', imageToData);
      stopCameraStream();
      updateCameraStatus(false);
      isImageCaptured.value = false;
    };

    return {
      createCameraElement,
      openCamera,
      isCameraModalOpen,
      updateCameraStatus,
      stopCameraStream,
      takePhoto,
      isImageCaptured,
      isLoading,
      cameraStream,
      saveImage,
      camera,
      canvas,
    };
  },
  watch: {
    isCameraOpen(value: boolean) {
      if (value) {
        this.openCamera();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.canvas {
  max-width: 100%;
}

.canvas-buttons {
  min-height: 100px;
}

.photo-button {
  width: 100px;
  height: 100px;
  position: absolute;
}

.circle {
  position: absolute;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 12%;
  border-radius: 100%;
  background-color: gray;
  opacity: 0;
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  border-radius: 100%;
  border: 0.5em solid gray;
  opacity: 0.8;
}

.photo-button .circle,
.photo-button .ring {
  transition: all 0.25s;
}

.photo-button:hover .circle {
  opacity: 1;
}

.photo-button .ring {
  opacity: 1;
}

.photo-button .circle {
  opacity: 0.5;
}

.canvas-container {
  & .camera,
  .canvas {
    height: 50vh;
  }

  & .canvas {
    object-fit: contain;
  }
}
</style>
