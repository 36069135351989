<template>
  <BaseModal :active="true" size="is-big" @closed="closeModal">
    <template v-if="captureImageInformation" #title>
      Bitte Stichwort oder Beschreibung zum Bild eingeben
    </template>
    <div v-if="captureImageInformation">
      <InteractiveDropdown
        v-model:initial-selected-options="localImage.keywords"
        :options-list="keywordList"
        placeholder="Stichwort eingeben"
        test-id="interactiveDropdown_imageKeywordInput"
        @update:selected-options="updateSelectedKeywords"
      />
      <BaseTextarea
        v-model:input-text="localImage.note"
        label="Notizen"
        test-id="textarea_imageNotes"
      />
    </div>
    <div class="object-contain">
      <img
        :src="image.getImage()"
        class="m-0 h-full w-full object-cover object-center"
        alt="image-placeholder"
        data-testId="image_fullSize"
      />
    </div>
    <template v-if="captureImageInformation" #buttons>
      <TertiaryButton
        label="Abbrechen"
        data-testid="button-cancel"
        @click="closeModal"
      />
      <PrimaryButton
        label="Speichern"
        data-testid="button-save"
        @click="updateImageInformation()"
      />
    </template>
  </BaseModal>
</template>
<script setup lang="ts">
import InteractiveDropdown from '@/components/UI/Dropdown/InteractiveDropdown.vue';
import BaseModal from '@/components/UI/Modal/BaseModal.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import { ref, watch } from 'vue';
import { Image } from '@/models/Image';
import { useImagesStore } from '@/store/images/imageStore';
import { instanceToInstance } from 'class-transformer';

interface Props {
  image?: Image;
  isImageOpen: boolean;
  captureImageInformation?: boolean;
  imageKeywordList?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  image: undefined,
  imageKeywordList: undefined,
});

const emit = defineEmits(['update:is-image-open', 'update-image-information']);

const imagesStore = useImagesStore();

let localImage = instanceToInstance(props.image);

const closeModal = () => {
  emit('update:is-image-open', false);
  localImage = undefined;
};

const keywordList = ref<string[]>([
  ...imagesStore.getKeywordList(localImage?.objectType),
]);

const updateKeywordList = (keywords: string[]) => {
  if (keywords.length > 0) {
    imagesStore.updateKeywordList(localImage?.objectType, [
      ...keywordList.value,
      ...keywords,
    ]);
  }
};

watch(
  () => props.imageKeywordList,
  (newKeywordList) => {
    if (newKeywordList && localImage) {
      updateKeywordList(newKeywordList);
      keywordList.value = [
        ...imagesStore.getKeywordList(localImage?.objectType),
      ];
    }
  },
  { immediate: true }
);

const updateSelectedKeywords = (keywords: string[]) => {
  if (!localImage) return;
  localImage.keywords = keywords;
};

export type ImageInformation = {
  id?: string;
  note?: string;
  keywords?: string[];
};

const updateImageInformation = () => {
  if (localImage) {
    const imageInformation: ImageInformation = {
      id: localImage?.id,
      note: localImage?.note,
      keywords: localImage?.keywords,
    };
    if (localImage.keywords?.length) {
      updateKeywordList(localImage.keywords);
    }
    emit('update-image-information', imageInformation);
  }
  closeModal();
};
</script>
