import { Form } from '@/models/Form';
import { Entity } from '@/models/types/Entity';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { entityTypes } from '@/enums/generic';
import { Pinia, Store } from '@/store/pinia-class-component';
import { useEntitiesStore } from '@/store/entities/entitiesStore';

export enum FormAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  REPLACE = 'REPLACE',
  MOUNT = 'MOUNT',
}
export function useFormStore() {
  return new FormStore();
}

@Store
export class FormStore extends Pinia {
  private _currentForm: Form;
  private _lastForm: Form;
  private _editNode?: Entity;
  private _hasFormChanges: boolean;
  private _formAction?: FormAction;

  get currentForm(): Form {
    return this._currentForm;
  }

  get lastForm(): Form {
    return this._lastForm;
  }

  constructor() {
    super();
    this._currentForm = new Form();
    this._lastForm = new Form();
    this._formAction = undefined;
    this._hasFormChanges = false;
  }

  get getForm() {
    return this._currentForm;
  }

  get formAction() {
    return this._formAction;
  }

  setFormAction(action: FormAction) {
    this._formAction = action;
  }

  get editNode() {
    return this._editNode;
  }

  get hasFormChanges() {
    return this._hasFormChanges;
  }

  setFormChangesState(state: boolean) {
    this._hasFormChanges = state;
  }

  isEditMode(): boolean {
    return !!this._currentForm.id;
  }

  changeCurrentNode(currentNode: Entity) {
    this._editNode = currentNode;
  }

  changeCurrentForm(form: Form) {
    if (form.setHistory) {
      const previousForm = new Form();
      const clonedObject = JSON.parse(JSON.stringify(this._currentForm));
      Object.assign(previousForm, clonedObject);
      this._lastForm = previousForm;
    }

    this._currentForm = form;
  }

  setToLastForm() {
    const lastForm = this._lastForm;
    lastForm.hasHistory(true);

    this.changeCurrentForm(lastForm);
  }

  setCurrentForm(form: Form) {
    this.changeCurrentForm(form);
  }

  resetCurrentForm() {
    this.changeCurrentForm(new Form());
    this._hasFormChanges = false;
    this._editNode = undefined;
  }

  setCurrentFormByEntity(
    entity: Entity,
    parentId = '',
    payload = {},
    history = false
  ) {
    if (entity.type === entityTypes.businessEntity) {
      return;
    }
    const entityParentId = parentId ? parentId : entity.getParentId();

    const form = new Form()
      .setName(entity.type)
      .setId(entity.id)
      .hasHistory(history)
      .setPayload(payload);

    if (entityParentId) {
      form.setParentId(entityParentId);
    }

    this.changeCurrentForm(form);
  }

  setCurrentFormByName(name: string, history?: boolean) {
    this.changeCurrentForm(
      new Form().setName(name).hasHistory(history ? history : false)
    );
  }

  createEntityForm(form: Form) {
    const layoutStore = useLayoutStore();
    layoutStore.closeFormDialog();
    layoutStore.openSideMenu();

    this.setCurrentForm(form);
  }

  createEntity(form: Form) {
    const layoutStore = useLayoutStore();

    this.setCurrentForm(form);
    layoutStore.openFormDialog();
    useFormStore().setFormAction(FormAction.CREATE);
  }

  editEntity(entity: Entity, parentId = '', payload = {}, history = false) {
    const layoutStore = useLayoutStore();
    layoutStore.openFormDialog();

    this.setCurrentFormByEntity(entity, parentId, payload, history);
    useFormStore().setFormAction(FormAction.EDIT);
  }

  editDevice(entity: Entity, parentId = '', payload = {}, history = false) {
    this.setCurrentFormByEntity(entity, parentId, payload, history);
    this.setFormAction(FormAction.EDIT);
    if (entity?.activeDeviceId) {
      useFormStore().changeCurrentNode(
        useEntitiesStore().getEntityById(entity.activeDeviceId)
      );
    }
    if (entity?.activeTestDeviceId) {
      useFormStore().changeCurrentNode(
        useEntitiesStore().getEntityById(entity.activeTestDeviceId)
      );
    }

    useLayoutStore().openFormDialog();
  }

  mountDevice(entity: Entity, parentId = '', payload = {}, history = false) {
    this.setCurrentFormByEntity(entity, parentId, payload, history);
    this.setFormAction(FormAction.MOUNT);
    useLayoutStore().openFormDialog();
  }

  replaceDevice(entity: Entity) {
    this.setCurrentFormByEntity(entity, undefined, {}, false);
    this.setFormAction(FormAction.REPLACE);
    useFormStore().changeCurrentNode(
      useEntitiesStore().getEntityById(entity?.activeDeviceId)
    );
    useLayoutStore().openFormDialog();
  }

  showCurrentFormList(type: string) {
    const layoutStore = useLayoutStore();

    const currentNodeId = layoutStore.currentNode?.id;
    if (!currentNodeId) {
      throw new Error('No current node id');
    }

    this.setCurrentForm(new Form().setName(type).setParentId(currentNodeId));
    layoutStore.openFormDialog();
  }
}
