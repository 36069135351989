<template>
  <form class="flex flex-col" @submit.prevent>
    <h2 class="mt-0">Passwort setzen</h2>
    <AuthenticationMessage :user-auth="authenticationMessage" />
    <div>
      <BaseInput
        v-model="user.newPassword"
        label="Passwort"
        type="password"
        placeholder="Passwort"
        :validation="v$.user.newPassword"
      />

      <BaseInput
        v-model="user.confirmPassword"
        class="mt-5"
        label="Passwort bestätigen"
        placeholder="Passwort bestätigen"
        type="password"
        :validation="v$.user.confirmPassword"
      />
    </div>

    <div class="mt-4 flex justify-between">
      <TertiaryButton icon="mdi-arrow-left" label="Zurück" @click="goBack()" />

      <PrimaryButton
        label="Passwort setzen"
        :class="[{ 'is-loading': isLoading }]"
        :disabled="isSubmitDisabled"
        @click="setNewPassword()"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import AuthenticationMessage from '@/components/Login/AuthenticationMessage.vue';
import { UserAuth } from '@/models/auth/UserAuth';
import { User } from '@/models/auth/User';
import { Validation } from '@vuelidate/core';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';

export default defineComponent({
  name: 'ChangePassword',
  components: {
    PrimaryButton,
    TertiaryButton,
    AuthenticationMessage,
    BaseInput,
  },
  emits: ['update:user', 'go-to-login-form', 'set-new-password'],
  setup(_props, { emit }) {
    /*
     * Injected properties
     */
    const user = inject<User>('user');
    const authenticationMessage = inject<UserAuth>('authenticationMessage');
    const v$ = inject<Ref<Validation>>('v$');
    const isLoading = inject<boolean>('isLoading');

    const goBack = () => {
      emit('go-to-login-form');
    };

    const setNewPassword = () => {
      emit('set-new-password');
    };

    const isSubmitDisabled = computed(() => {
      return v$?.value.$validationGroups?.changePassword.$invalid;
    });

    return {
      user,
      authenticationMessage,
      v$,
      isLoading,
      goBack,
      setNewPassword,
      isSubmitDisabled,
    };
  },
});
</script>
