import { BusinessEntity } from '@/models';
import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';

export function useBusinessEntityStore() {
  return new BusinessEntityStore();
}

@Store
export class BusinessEntityStore extends miniEntityStore {
  private _businessEntity: BusinessEntity | undefined;

  constructor() {
    super();
    this._businessEntity = undefined;
  }

  get businessEntity(): BusinessEntity | undefined {
    return this._businessEntity;
  }

  addEntities(entities: BusinessEntity[]): void {
    this.addEntity(entities[0]);
  }

  addEntity(businessEntity: BusinessEntity): void {
    this._businessEntity = businessEntity;
  }

  deleteEntity(entity: BusinessEntity): void {
    this._businessEntity = undefined;
  }

  resetState() {
    this._businessEntity = undefined;
  }
}
