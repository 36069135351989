<template>
  <div class="flex grow flex-col md:h-full">
    <div v-if="!radiatorType">
      <BaseInformation
        v-if="isPlaceholderRadiator"
        information-text="Dieser Heizkörper wurde noch nicht identifiziert. Bitte wähle einen
          Heizkörpertyp aus und gebe daraufhin die erforderlichen Daten ein."
        status="warning"
      />
      <BaseSection heading="Heizkörpertyp auswählen">
        <RadiatorTypeForm v-model:type="radiatorType" />
      </BaseSection>
    </div>

    <div class="h-full w-full overflow-y-auto overflow-x-hidden">
      <component :is="formComponent" v-model:radiator="radiator" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  watch,
  defineProps,
  defineEmits,
  inject,
  defineAsyncComponent,
} from 'vue';
import RadiatorTypeForm from './RadiatorFormComponents/RadiatorTypeForm.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import { createRadiator } from '@/models/radiators';
import { radiatorTypes } from '@/enums/radiator/generic';
import { getRadiatorStepsStatus } from './Helper/radiatorStepHelper';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { Radiator } from '@/models/radiators/Radiator';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import { instanceToInstance } from 'class-transformer';

const radiatorComponents = {
  PP: import(
    '@/components/Forms/RadiatorFormComponents/PP/RadiatorPPWizard.vue'
  ),
  GS: import(
    '@/components/Forms/RadiatorFormComponents/GS/RadiatorGSWizard.vue'
  ),
  HW: import(
    '@/components/Forms/RadiatorFormComponents/HW/RadiatorHWWizard.vue'
  ),
  GG: import(
    '@/components/Forms/RadiatorFormComponents/GG/RadiatorGGWizard.vue'
  ),
  GR: import(
    '@/components/Forms/RadiatorFormComponents/GR/RadiatorGRWizard.vue'
  ),
  PG: import(
    '@/components/Forms/RadiatorFormComponents/PG/RadiatorPGWizard.vue'
  ),
  UNKNOWN: import(
    '@/components/Forms/RadiatorFormComponents/Unknown/RadiatorUnknownWizard.vue'
  ),
};

const formComponent = computed(() => {
  const radiatorTypeIndex = radiatorType.value;
  if (radiatorTypeIndex) {
    return defineAsyncComponent({
      loader: () => radiatorComponents[radiatorTypeIndex],
    });
  }
  return null;
});

const props = defineProps<{
  node: Radiator | undefined;
  parentId: string;
}>();

const emit = defineEmits(['update-entity', 'sync-local-radiator']);

const notificationStore = useNotificationStore();
const radiatorStore = useRadiatorStore();
const radiatorType = ref(props.node?.radiatorType);
const radiator = ref(props.node);

const isPlaceholderRadiator = computed(() =>
  radiator.value?.isPlaceholderRadiator()
);

watch(radiatorType, () => {
  radiator.value = createRadiator(radiatorType.value);
  if (props.node?.id) {
    radiator.value.id = props.node.id;
  }
  radiator.value.parentRoomId = props.parentId;
  radiator.value.ordinal = radiatorStore.getNewRadiatorOrdinal(
    radiator.value.parentRoomId
  );
  emit('sync-local-radiator', radiator.value);
});

watch(
  () => props.node?.id,
  () => {
    if (props.node) {
      radiatorType.value = props.node?.radiatorType;
      radiator.value = createRadiator(radiatorType.value);
      radiator.value = instanceToInstance(props.node);
      emit('sync-local-radiator', radiator.value);
    }
  },
  { immediate: true }
);
</script>

<style scoped></style>
