<template>
  <DeviceTypeSelection
    v-if="!deviceType"
    :device-type="deviceType"
    @update:device-type="(newValue) => onDeviceTypeSelection(newValue)"
  />

  <component
    :is="formComponent"
    v-if="deviceType && inspectionPoint && inspectionDevice"
    ref="formComponentRef"
    v-model:device="inspectionDevice"
    v-model:installationPoint="inspectionPoint"
    v-model:inspectionData="inspectionData"
    data-testid="deviceForm"
    @close-and-save-form="handleSubmit"
  />
</template>

<script setup lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineProps,
  onBeforeMount,
  ref,
  Ref,
} from 'vue';
import DeviceTypeSelection from '@/components/Forms/DeviceFormComponents/DeviceTypeSelection.vue';
import { DeviceType } from '@/models/devices/Device';
import { DeviceTypeEnum } from '@/enums/device';
import { useFormStore } from '@/store/form/formStore';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import { useInspectionForm } from '@/composables/useInspectionForm';
import { useInspectionDeviceStore } from '@/store/entities/inspectionDeviceStore';
import {
  InspectionDevice,
  InspectionDeviceType,
} from '@/models/inspectionPoint/InspectionDevice';

const props = defineProps<{
  node?: InspectionPoint;
  parentId: string;
  payload?: any;
}>();
const emit = defineEmits(['update-entity', 'update-entities']);

const {
  inspectionDevice,
  inspectionPoint,
  inspectionData,
  initInspectionDevice,
  initInspectionPoint,
  initInspectionData,
  updateInspectionDevice: updateNewDevice,
} = useInspectionForm(emit);

const deviceType = ref(props.payload?.deviceType);

const onDeviceTypeSelection = (newDeviceType: DeviceType) => {
  deviceType.value = newDeviceType;
  const roomId = props.parentId;

  initInspectionPoint(roomId);
  if (!inspectionPoint.value || !deviceType.value) {
    throw new Error('Something went wrong with init functions');
  }

  initNewDevice(deviceType.value);
  useFormStore().changeCurrentNode(inspectionDevice.value);
};

onBeforeMount(() => {
  if (props.node?.id) {
    initInspectionPoint(props.parentId, props.node);
    initExistingDevices(inspectionPoint.value);
  }

  initInspectionData();
});

const initExistingDevices = (inspectionPoint?: InspectionPoint) => {
  if (!inspectionPoint || !inspectionPoint.activeTestDeviceId) {
    throw new Error('inspection point without device');
  }
  const activeDevice = useInspectionDeviceStore().inspectionDevices.get(
    inspectionPoint.activeTestDeviceId
  ) as InspectionDevice;

  deviceType.value = activeDevice.deviceType;
  initInspectionDevice(activeDevice.deviceType, activeDevice);
};

const fillLoRaGatewayForInspection = () => {
  const gateways = useInspectionDeviceStore().getAllActiveGateways();

  if (gateways.length > 0 && inspectionDevice.value) {
    inspectionDevice.value.eui = gateways[0].eui;
    inspectionDevice.value.productKey = gateways[0].productKey;
    inspectionDevice.value.serialNumber = gateways[0].serialNumber;
    inspectionDevice.value.note = gateways[0].note;
  }
};

const initNewDevice = (deviceType: InspectionDeviceType) => {
  initInspectionDevice(deviceType);

  if (deviceType === DeviceTypeEnum.LO_RA_WAN_GATEWAY) {
    fillLoRaGatewayForInspection();
  }
};

const handleSubmit = () => {
  if (
    inspectionPoint.value?.supportedDeviceType ===
    DeviceTypeEnum.LO_RA_WAN_GATEWAY
  ) {
    inspectionPoint.value?.addInspectionData(inspectionData.value);
  }

  updateNewDevice();

  if (formComponentRef.value?.saveImage) {
    if (inspectionPoint.value) {
      formComponentRef.value?.saveImage(inspectionPoint.value.id);
    }
  }
};

type FormComponentRef = {
  isFormValid: boolean;
  saveImage: (id: string) => void;
  saveRadiator: () => void;
  hasImage?: boolean;
};
const formComponentRef: Ref<FormComponentRef | null> = ref(null);
const isFormValid = computed(() => {
  return formComponentRef.value?.isFormValid;
});
defineExpose({
  isFormValid,
  handleSubmit,
  hasImage: computed(() => formComponentRef.value?.hasImage),
});

const deviceComponents: any = {
  SMART_RADIATOR_THERMOSTAT: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/InspectionForms/SRTInspectionForm.vue'
  ),
  LO_RA_WAN_GATEWAY: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LoRaWanGatewayForm.vue'
  ),
};

const formComponent = computed(() => {
  const deviceTypeIndex = deviceType.value;
  if (deviceTypeIndex) {
    return defineAsyncComponent({
      loader: () => deviceComponents[deviceTypeIndex],
    });
  }
  return null;
});
</script>
