import { RadioOption } from '@/enums/radiator/generic';

export const wizardStepsPG = Object.freeze({
  DIMENSIONS: 'Maße',
  SPACING_AND_PANEL_THICKNESS: 'Teilung und Plattendicke',
  LAMELLA_DEPTH: 'Lamellentiefe',
  LAMELLA_SHAPE: 'Lamellenform',
  LAMELLA_CONNECTION: 'Lamellenanschluss',
  LAYERS: 'Lagenaufbau',
  PROFILE_SHAPE: 'Profilform',
  HAS_COVER: 'Verkleidung',
  ROTATED_90_DEGREES: '90 Grad gedreht',
  GAP_PARAMETER: 'Fugenparameter',
  CONNECTION_TYPE: 'Anschlussart',
  MANUFACTURER: 'Hersteller',
});

export const imagesPG = Object.freeze({
  LAMELLA_DEPTH: new URL(
    '@/assets/img/radiators/PG/lamellaDepth.svg',
    import.meta.url
  ).href,
  SPACING_AND_PANEL_THICKNESS: new URL(
    '@/assets/img/radiators/PG/panel-thickness-and-spacing.svg',
    import.meta.url
  ).href,
});

export const lamellaShapesPG = Object.freeze([
  {
    name: '0',
    subtitle: 'ohne Delle',
    value: 0,
    image: new URL(
      '@/assets/img/radiators/common/lamellaShapes/LF_00.svg',
      import.meta.url
    ).href,
  },
  {
    name: '1',
    subtitle: 'eine Delle',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/common/lamellaShapes/LF_01.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: 'zwei Dellen',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/common/lamellaShapes/LF_02.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: 'einfach, oben schräg',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/common/lamellaShapes/LF_03.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: 'einfach, unten schräg',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/common/lamellaShapes/LF_04.svg',
      import.meta.url
    ).href,
  },
  {
    name: '5',
    subtitle: 'eingedellt, unten Schräg',
    value: 5,
    image: new URL(
      '@/assets/img/radiators/common/lamellaShapes/LF_05.svg',
      import.meta.url
    ).href,
  },
]);

export const lamellaConnectionsPG = Object.freeze([
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/common/lamellaConnections/LC__01.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/common/lamellaConnections/LC__02.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/common/lamellaConnections/LC__03.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/common/lamellaConnections/LC__04.svg',
      import.meta.url
    ).href,
  },
]);

export const profileShapesPG = Object.freeze([
  {
    name: 'Profilform 10',
    subtitle: '',
    value: {
      distributionChannel: 1,
      transition: 0,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_10.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 11',
    subtitle: '',
    value: {
      distributionChannel: 1,
      transition: 1,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_11.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 14',
    subtitle: '',
    value: {
      distributionChannel: 1,
      transition: 4,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_14.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 20',
    subtitle: '',
    value: {
      distributionChannel: 2,
      transition: 0,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_20.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 21',
    subtitle: '',
    value: {
      distributionChannel: 2,
      transition: 1,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_21.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 22',
    subtitle: '',
    value: {
      distributionChannel: 2,
      transition: 2,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_22.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 23',
    subtitle: '',
    value: {
      distributionChannel: 2,
      transition: 3,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_23.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 24',
    subtitle: '',
    value: {
      distributionChannel: 2,
      transition: 4,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_24.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 32',
    subtitle: '',
    value: {
      distributionChannel: 3,
      transition: 2,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_32.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 40',
    subtitle: '',
    value: {
      distributionChannel: 4,
      transition: 0,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_40.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 42',
    subtitle: '',
    value: {
      distributionChannel: 4,
      transition: 2,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_42.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 45',
    subtitle: '',
    value: {
      distributionChannel: 4,
      transition: 5,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_45.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 46',
    subtitle: '',
    value: {
      distributionChannel: 4,
      transition: 6,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_46.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 50',
    subtitle: '',
    value: {
      distributionChannel: 5,
      transition: 0,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_50.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 51',
    subtitle: '',
    value: {
      distributionChannel: 5,
      transition: 1,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_51.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 52',
    subtitle: '',
    value: {
      distributionChannel: 5,
      transition: 2,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_52.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 60',
    subtitle: '',
    value: {
      distributionChannel: 6,
      transition: 0,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_60.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 61',
    subtitle: '',
    value: {
      distributionChannel: 6,
      transition: 1,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_61.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 63',
    subtitle: '',
    value: {
      distributionChannel: 6,
      transition: 3,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_63.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 64',
    subtitle: '',
    value: {
      distributionChannel: 6,
      transition: 4,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_64.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 66',
    subtitle: '',
    value: {
      distributionChannel: 6,
      transition: 6,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_66.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 67',
    subtitle: '',
    value: {
      distributionChannel: 6,
      transition: 7,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_67.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 68',
    subtitle: '',
    value: {
      distributionChannel: 6,
      transition: 8,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_68.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 70',
    subtitle: '',
    value: {
      distributionChannel: 7,
      transition: 0,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_70.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 80',
    subtitle: '',
    value: {
      distributionChannel: 8,
      transition: 0,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_80.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 91',
    subtitle: '',
    value: {
      distributionChannel: 9,
      transition: 1,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_91.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 92',
    subtitle: '',
    value: {
      distributionChannel: 9,
      transition: 2,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_92.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 05',
    subtitle: '',
    value: {
      distributionChannel: 0,
      transition: 5,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_05.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Profilform 09',
    subtitle: '',
    value: {
      distributionChannel: 0,
      transition: 9,
    },
    image: new URL(
      '@/assets/img/radiators/common/profileShapes/PF_09.svg',
      import.meta.url
    ).href,
  },
]);

export const radiatorCoverPG: Array<RadioOption> = [
  {
    name: 'Mit Verkleidung',
    subtitle: '',
    value: true,
    image: new URL(
      '@/assets/img/radiators/PG/PG_with_cover.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'Ohne Verkleidung',
    subtitle: '',
    value: false,
    image: new URL(
      '@/assets/img/radiators/PG/PG_without_cover.svg',
      import.meta.url
    ).href,
  },
];

export const rotated90degreesPG: Array<RadioOption> = [
  {
    name: '90 Grad gedreht',
    subtitle: '',
    value: true,
    image: new URL(
      '@/assets/img/radiators/PG/PG_without_cover_rotated.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'nicht gedreht',
    subtitle: '',
    value: false,
    image: new URL(
      '@/assets/img/radiators/PG/PG_without_cover.svg',
      import.meta.url
    ).href,
  },
];

export const gapParameterPG = Object.freeze([
  {
    name: 'WF',
    value: 'WF',
  },
  {
    name: 'SF',
    value: 'SF',
  },
]);

export const connectionTypesPG = Object.freeze([
  {
    name: 'E',
    subtitle: 'Gleichseitiger Anschluss, mit oberem Vorlauf',
    value: 'E1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_E1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'E',
    subtitle: 'Untere Anbindung mit Trennscheibe im Vorlauf',
    value: 'E2',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_E2.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E3',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_E3.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'E',
    subtitle: 'Obere Vorlaufeinführung in Verbindung mit unterer Anbindung',
    value: 'E4',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_E4.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'E',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'E5',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_E5.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'E',
    subtitle: 'Mittenanschluss mit Ventileinsatz im oberen Verteilkanal',
    value: 'E6',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_E6.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'W',
    subtitle: 'Vorlauf oben, Rücklauf unten',
    value: 'W1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_W1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'W',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'W2',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_W2.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'W',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'W3',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_W3.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'W',
    subtitle:
      'Untere Vorlaufeinführung Achtung! Keine HKV-Montage, siehe Handbuch',
    value: 'W4',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_W4.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'R',
    subtitle: 'Beide Anschlüsse unten, links und rechts',
    value: 'R1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_R1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'R',
    subtitle: 'Kurzschlussstrecke (Einrohrsystem)',
    value: 'R2',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_R2.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'R',
    subtitle: 'Reihenschaltung von mehreren Heizkörpern',
    value: 'R3',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_R3.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'S',
    subtitle: 'Mit unterer Trennscheibe am Vorlauf oder mittig',
    value: 'S1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_S1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'S',
    subtitle:
      'Obere Einführung von Vor- und Rücklauf, obere Trennscheibe am Rücklauf',
    value: 'S2',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_S2.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'S',
    subtitle: 'Reihenschaltung mit Trennscheibe vorlaufseitig',
    value: 'S3',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_S3.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'M',
    subtitle: 'Mittenanschluss mit Ventileinsatz am Vorlaufanschluss',
    value: 'M1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_M1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'L',
    subtitle: 'Lanzenventil',
    value: 'L1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_L1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'L',
    subtitle: 'Sonderform Lanzenventil',
    value: 'L2',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_L2.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'L',
    subtitle: 'Lanzenventil',
    value: 'L3',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_L3.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'A',
    subtitle: 'Vorlauf oben, Rücklauf unten, einseitig, HK um 90° gedreht',
    value: 'A1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_A1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'B',
    subtitle: 'Vorlauf oben, Rücklauf unten, wechselseitig, HK um 90° gedreht',
    value: 'B1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_B1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'C',
    subtitle: 'Vorlauf und Rücklauf unten, HK um 90° gedreht',
    value: 'C1',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_C1.svg',
      import.meta.url
    ).href,
  },
]);

export const layerTypePG = Object.freeze(['P', 'L', 'A']);
