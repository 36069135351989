<template>
  <div
    class="flex w-full flex-col overflow-auto border-b pl-5 dark:border-dark-2 md:border-b-0 md:border-l"
  >
    <div class="mb-2 text-xl font-bold">
      Heizkörper identifizieren <Spinner v-if="isLoading" class="ml-2" />
    </div>
    <div
      v-if="radiatorRatings.length > 0"
      class="relative flex flex-row gap-3 md:flex-col"
      data-testId="radiatorIdentificationResults"
    >
      <RadiatorIdentificationCard
        v-for="rating in radiatorRatings"
        :key="rating.idNumber + rating.variant + rating.confidence"
        :rating="rating"
        :is-selected="isSelected(rating)"
        @click="selectRating(rating)"
      />
    </div>
    <BaseInformation
      v-if="!isNative && radiatorRatings.length === 0"
      status="warning"
      :information-text="i18n.info.identification.android"
    />
    <BaseInformation
      v-if="isNative && radiatorRatings.length === 0"
      status="warning"
      :information-text="i18n.info.identification.web"
    />

    <div v-if="hasError" class="error-message">
      <em class="mdi mdi-alert mr-2" />
      <span>{{ i18n.info.identification.error }} </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Notification } from '@/models';
import {
  PPRadiator,
  Radiator,
  RadiatorClasses,
} from '@/models/radiators/Radiator';
import { RadiatorIdentification } from '@/native/plugins/RadiatorIdentification';
import { RadiatorIdentificationFactory } from '@/api/radiator/RadiatorIdentificationFactory';
import RadiatorIdentificationCard from '@/components/Forms/RadiatorFormComponents/Identification/RadiatorIdentificationCard.vue';
import { Capacitor } from '@capacitor/core';
import Spinner from '@/components/UI/Loader/Spinner.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import i18n from '@/utils/i18n/radiator.json';

const props = defineProps<{
  radiator: RadiatorClasses;
  isFormValid: boolean;
}>();

const emits = defineEmits(['update-entity']);

const radiatorRatings = ref<Array<RadiatorRating>>([]);
const selectedRating = ref<RadiatorRating | null>(null);
const hasError = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const selectRating = (rating: RadiatorRating) => {
  const radiator = props.radiator;
  radiator.identificationResult = rating;
  emits('update-entity', radiator);
};
const isSelected = computed(() => {
  return (rating: RadiatorRating) => {
    const selectedRatingValue = props.radiator.identificationResult;
    if (!selectedRatingValue) {
      return false;
    }
    return (
      rating.confidence === selectedRatingValue.confidence &&
      rating.idNumber === selectedRatingValue.idNumber &&
      rating.variant === selectedRatingValue.variant &&
      rating.kQ === selectedRatingValue.kQ
    );
  };
});

onMounted(() => {
  if (props.isFormValid) {
    if (isNative.value) {
      identifyAndroid();
    } else {
      identifyWeb();
    }
  }
});

watch(
  () => ({ ...props.radiator }),
  (oldValue, newValue) => {
    if (
      JSON.stringify(oldValue.identificationResult) !==
      JSON.stringify(newValue.identificationResult)
    ) {
      return;
    }

    identifyRadiator();
  },
  {
    deep: true,
  }
);

watch(
  () => radiatorRatings.value,
  (newValue) => {
    if (
      !newValue.some(
        (rating) =>
          rating.idNumber === props.radiator.identificationResult?.idNumber
      )
    ) {
      const localRadiator = { ...props.radiator };
      localRadiator.identificationResult = undefined;
      emits('update-entity', localRadiator);
    }
  }
);

watch(
  () => props.isFormValid,
  (newValue) => {
    if (newValue) {
      identifyRadiator();
    }
  }
);

const isNative = computed(() => {
  return Capacitor.isNativePlatform();
});

const timeout = ref<ReturnType<typeof setTimeout> | null>(null);
const identifyRadiator = () => {
  if (isNative.value) {
    if (
      props.radiator.connectionType &&
      props.radiator.height &&
      props.radiator.length &&
      props.radiator.depth
    ) {
      if (props.radiator instanceof PPRadiator) {
        if (
          !(
            props.radiator.depth.withCoverPanel ||
            props.radiator.depth.withoutCoverPanel
          )
        ) {
          return;
        }
      }

      if (timeout.value) {
        clearTimeout(timeout.value);
      }

      timeout.value = setTimeout(() => {
        identifyAndroid();
      }, 2000); //
    } else {
      if (timeout.value) {
        clearTimeout(timeout.value);
      }
    }
  } else {
    if (props.isFormValid) {
      identifyWeb();
    }
  }
};

const identifyAndroid = async () => {
  const radiator = RadiatorIdentificationFactory.android(props.radiator);
  isLoading.value = true;
  await RadiatorIdentification.identifyRadiator(radiator).then((response) => {
    isLoading.value = false;
    radiatorRatings.value = response.values;
  });
};
const identifyWeb = async () => {
  isLoading.value = true;
  useRadiatorStore()
    .fetchRadiatorRatings(props.radiator as Radiator)
    .then((response) => {
      radiatorRatings.value = response;
      isLoading.value = false;
      if (props.radiator.identificationResult) {
        selectedRating.value = props.radiator.identificationResult;
      }
    })
    .catch((error) => {
      const notification = new Notification()
        .setType('error')
        .setTimeout(6000)
        .setText(
          'Die Identifizierung des Heizkörpers ist derzeit nicht möglich. Bitte fortfahren.'
        );
      hasError.value = false;
      useNotificationStore().addNotification(notification);
      isLoading.value = false;
      console.error(error);
    });
};
</script>

<style scoped lang="scss">
.loading-container {
  justify-content: center;
  display: flex;
  margin-top: 200px;
}

.error-message {
  background-color: #feecf0;
  padding: 16px;
  border-radius: 15px;
  text-align: center;
  margin-top: 8px;
  color: hsl(348deg, 86%, 61%);
}
</style>
