import * as Sentry from '@sentry/vue';
import { default as envConfig } from '@/config/runtimeConfig';
import { Router } from 'vue-router';

export const initSentry = (app: any, router: Router) => {
  Sentry.init({
    app,
    enabled: envConfig.sentryEnv != 'dev',
    dsn: envConfig.sentryDSN,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: envConfig.sentryEnv != 'dev' ? 1.0 : 0.0,
    normalizeDepth: 10,
    release: APP_VERSION,
    tracePropagationTargets: [/https:\/\/bxapp.*beyonnex.*/, /.*localhost.*/],
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration({ router }),
    ],
    environment: envConfig.sentryEnv,
    tracesSampleRate: 0.5,
  });
};
