<template>
  <form class="flex flex-col" @submit.prevent>
    <h2 class="mt-0">Passwort vergessen?</h2>
    <AuthenticationMessage :user-auth="authenticationMessage" />
    <p>
      Bitte geben Sie Ihren Benutzernamen ein, damit wir Ihnen einen Reset Code
      zum Passwort zurücksetzen schicken können.
    </p>
    <BaseInput
      v-model="user.username"
      label="Benutzername"
      placeholder="Benutzername"
      :validation="v$.user.username"
    />

    <div class="mt-4 flex justify-between">
      <TertiaryButton
        icon="mdi-arrow-left"
        label="Zurück"
        @click="goToLoginForm()"
      />
      <PrimaryButton
        type="submit"
        label="Passwort zurücksetzen"
        :class="[{ 'is-loading': isLoading }]"
        :disabled="isSubmitDisabled"
        @click="forgetPassword()"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import AuthenticationMessage from '@/components/Login/AuthenticationMessage.vue';
import { Validation } from '@vuelidate/core';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';

export default defineComponent({
  name: 'ForgetPassword',
  components: {
    TertiaryButton,
    PrimaryButton,
    BaseInput,
    AuthenticationMessage,
  },

  emits: ['update:user', 'go-to-login-form', 'send-recovery-email'],
  setup(_props, { emit }) {
    /*
     * Injected properties
     */
    const user = inject('user');
    const authenticationMessage = inject('authenticationMessage');
    const v$ = inject<Ref<Validation>>('v$');
    const isLoading = inject<boolean>('isLoading');

    const goToLoginForm = () => {
      emit('go-to-login-form');
    };

    const forgetPassword = () => {
      emit('send-recovery-email');
    };

    const isSubmitDisabled = computed(() => {
      return v$?.value.$validationGroups?.forgetPassword.$invalid;
    });

    return {
      authenticationMessage,
      v$,
      user,
      isLoading,
      isSubmitDisabled,
      goToLoginForm,
      forgetPassword,
    };
  },
});
</script>
