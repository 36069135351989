<template>
  <div class="field-group">
    <label class="label subtitle is-5 dark:text-white">{{ label }}</label>
    <fieldset class="columns">
      <div
        v-for="(option, index) in optionsList"
        :key="option.name + index"
        :class="'column field is-inline-block selector-button ' + radioClass"
        :set="(currentId = uniqueId())"
        :data-testid="label + '_' + option.name"
      >
        <input
          :id="currentId"
          v-model="localSelection"
          :disabled="isDisabled"
          :value="option.value"
          type="radio"
          :data-testid="'input_' + option.name"
        />
        <label
          :data-testid="'label_' + option.name"
          :for="currentId"
          :class="[
            'radio-label',
            { 'is-justify-content-center': optionsList.every((o) => !o.image) },
          ]"
        >
          <em v-if="option.icon" :class="option.icon" class="mdi"></em>
          <img
            v-if="option.image"
            class="max-image-height dark:invert"
            :src="option.image"
          />
          <p
            :class="{
              'has-text-weight-bold dark:text-white': 'subtitle' in option,
            }"
          >
            {{ option.name }}
          </p>
          <p v-if="option.subtitle" class="dark:text-white">
            {{ option.subtitle }}
          </p>
        </label>
      </div>
    </fieldset>
  </div>
</template>
<script>
export default {
  name: 'RadioSelector',
  props: {
    radioClass: {
      type: String,
      required: false,
      default: 'is-6',
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionsList: {
      type: Array,
      required: true,
    },
    selection: {
      type: [Number, String, Boolean, Object],
      required: false,
      default: undefined,
    },
  },
  // option-click lets the parent component detect the change in value and call a local method for example.
  // without the need for a watcher in the parent
  emits: ['update:selection', 'option-click'],
  computed: {
    localSelection: {
      get() {
        return this.selection;
      },
      set(value) {
        this.$emit('update:selection', value);
        this.$emit('option-click', value);
      },
    },
  },
  methods: {
    uniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
  },
};
</script>
<style scoped>
.max-image-height {
  align-self: center;
  display: flex;
  width: 120px;
  height: 120px;
  object-fit: contain;
}
fieldset {
  display: flex;
  flex-wrap: wrap;
}
.field:not(:last-child) {
  margin: 0 !important;
}
.radio-label {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  word-break: break-word;
}
</style>
