<template>
  <table class="my-3" aria-label="gateways">
    <thead>
      <tr>
        <th></th>
        <th class="pl-5">{{ i18n.label.serialNumber }}</th>
        <th>{{ i18n.label.eui }}</th>
        <th>{{ i18n.label.location }}</th>
      </tr>
    </thead>
    <tbody v-if="gateways.length > 0">
      <GatewayTableElement
        v-for="(gateway, index) in gateways"
        :key="gateway.id"
        :gateway="gateway"
        :index="index"
      />
    </tbody>
  </table>
</template>
<script setup lang="ts">
import { useDeviceStore } from '@/store/entities/deviceStore';
import GatewayTableElement from '@/components/LoRaWanRadioCheck/GatewayTableElement.vue';
import i18n from '@/utils/i18n/radiocheck.json';
import { LoRaWanGateway } from '@/models/devices';

const gateways: LoRaWanGateway[] = useDeviceStore().getLoRaWanGateways;
</script>
