<template>
  <div>
    <div
      class="m-2 min-w-[15rem] rounded-md bg-light-1 p-4 px-5 shadow dark:bg-dark-2"
      :testid="testId"
      @click="hasOpenStatus = !hasOpenStatus"
    >
      <div class="flex justify-between">
        <div>
          <em class="mdi primary" :class="headerIcon" />
          <span class="ml-2">
            {{ text }}
          </span>
        </div>
        <div><slot name="actionButtons"></slot></div>
      </div>
      <div v-if="icon">
        <em class="mdi" :class="icon"></em>
      </div>
    </div>
    <div v-show="hasOpenStatus">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordionCard',
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    headerIcon: {
      type: String,
      required: false,
      default: undefined,
    },
    isOpen: {
      type: Boolean,
      default: false,
      required: false,
    },
    testId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      hasOpenStatus: this.isOpen,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.primary {
  color: $primary-color;
}
.error {
  color: $error-color;
}
.success {
  color: $success-color;
}
.warning {
  color: $warning-color;
}
</style>
