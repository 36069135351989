<template>
  <form class="flex flex-col" @submit.prevent>
    <h2 class="mt-0">Passwort zurücksetzen</h2>
    <AuthenticationMessage :user-auth="authenticationMessage" />
    <div>
      <BaseInput
        v-model="user.resetCode"
        label="Reset Code"
        placeholder=""
        :validation="v$.user.resetCode"
      />

      <BaseInput
        v-model="user.newPassword"
        class="mt-5"
        label="Passwort"
        type="password"
        placeholder="Passwort"
        :validation="v$.user.newPassword"
      />

      <BaseInput
        v-model="user.confirmPassword"
        class="mt-5"
        label="Passwort bestätigen"
        placeholder="Passwort bestätigen"
        type="password"
        :validation="v$.user.confirmPassword"
      />
    </div>

    <div class="mt-4 flex justify-between">
      <TertiaryButton
        icon="mdi-arrow-left"
        label="Zurück"
        @click="goToLoginForm()"
      />
      <PrimaryButton
        label="Passwort ändern"
        :class="[{ 'is-loading': isLoading }]"
        :disabled="isSubmitDisabled"
        @click="validateAndChangePassword()"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue';
import BaseInput from '@/components/UI/Input/BaseInput.vue';
import AuthenticationMessage from '@/components/Login/AuthenticationMessage.vue';
import { UserAuth } from '@/models/auth/UserAuth';
import { User } from '@/models/auth/User';
import { Validation } from '@vuelidate/core';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import PrimaryButton from '@/components/UI/Button/PrimaryButton.vue';

export default defineComponent({
  name: 'ResetPassword',
  components: {
    PrimaryButton,
    TertiaryButton,
    BaseInput,
    AuthenticationMessage,
  },
  emits: ['go-to-login-form', 'validate-change-password'],
  setup(_props, { emit }) {
    /*
     * Injected properties
     */
    const user = inject<User>('user');
    const v$ = inject<Ref<Validation>>('v$');
    const authenticationMessage = inject<UserAuth>('authenticationMessage');
    const isLoading = inject<boolean>('isLoading');

    const validateAndChangePassword = () => {
      emit('validate-change-password');
    };

    const goToLoginForm = () => {
      emit('go-to-login-form');
    };

    const isSubmitDisabled = computed(() => {
      return v$?.value.$validationGroups?.resetPassword.$invalid;
    });

    return {
      user,
      v$,
      isLoading,
      authenticationMessage,
      isSubmitDisabled,
      goToLoginForm,
      validateAndChangePassword,
    };
  },
});
</script>
