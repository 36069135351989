export const wizardStepsHW = Object.freeze({
  PIPE_COUNT: 'Rohrzahl',
  DIMENSIONS: 'Maße',
  SPACING: 'Teilung',
  PIPE_STRENGTH: 'Rohrstärke',
  DISTRIBUTION_CHANNEL_STRENGTH: 'Verteilkanalstärke',
  DISTRIBUTION_CHANNEL_SHAPE: 'Form Verteilkanal',
  DISTRIBUTION_CHANNEL_STRUCTURE: 'Aufbau Verteilkanal',
  PIPE_SHAPE: 'Form Rohre',
  PIPE_TOP_VIEW: 'Draufsicht Rohre',
  PIPE_CONNECTION: 'Anbindung Rohre',
  PIPE_ROUTING: 'Rohrführung',
  CONNECTION_TYPE: 'Anschlussart',
  MANUFACTURER: 'Hersteller',
});

export const wizardStepImagesHW = Object.freeze({
  SPACING: new URL(
    '@/assets/img/radiators/HW/spacing/spacing.svg',
    import.meta.url
  ).href,
  DIMENSIONS: new URL(
    '@/assets/img/radiators/HW/dimensions.svg',
    import.meta.url
  ).href,
  DISTRIBUTION_CHANNEL_STRENGTH: new URL(
    '@/assets/img/radiators/HW/distribution_chamber_strength.png',
    import.meta.url
  ).href,
  PIPE_STRENGTH: new URL(
    '@/assets/img/radiators/HW/pipe_strength.png',
    import.meta.url
  ).href,
});

export const pipeRoutingHW = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: new URL(
      '@/assets/img/radiators/HW/pipeRouting/RF_0.svg',
      import.meta.url
    ).href,
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/HW/pipeRouting/RF_1.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/HW/pipeRouting/RF_2.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/HW/pipeRouting/RF_3.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/HW/pipeRouting/RF_4.svg',
      import.meta.url
    ).href,
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: new URL(
      '@/assets/img/radiators/HW/pipeRouting/RF_5.svg',
      import.meta.url
    ).href,
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: new URL(
      '@/assets/img/radiators/HW/pipeRouting/RF_6.svg',
      import.meta.url
    ).href,
  },
]);

export const pipeConnectionHW = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: new URL(
      '@/assets/img/radiators/HW/pipeConnection/AR_0.svg',
      import.meta.url
    ).href,
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/HW/pipeConnection/AR_1.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/HW/pipeConnection/AR_2.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/HW/pipeConnection/AR_3.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/HW/pipeConnection/AR_4.svg',
      import.meta.url
    ).href,
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: new URL(
      '@/assets/img/radiators/HW/pipeConnection/AR_5.svg',
      import.meta.url
    ).href,
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: new URL(
      '@/assets/img/radiators/HW/pipeConnection/AR_6.svg',
      import.meta.url
    ).href,
  },
]);

export const pipeShapeHW = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: new URL(
      '@/assets/img/radiators/HW/pipeShape/FR_0.svg',
      import.meta.url
    ).href,
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/HW/pipeShape/FR_1.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/HW/pipeShape/FR_2.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/HW/pipeShape/FR_3.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/HW/pipeShape/FR_4.svg',
      import.meta.url
    ).href,
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: new URL(
      '@/assets/img/radiators/HW/pipeShape/FR_5.svg',
      import.meta.url
    ).href,
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: new URL(
      '@/assets/img/radiators/HW/pipeShape/FR_6.svg',
      import.meta.url
    ).href,
  },
  {
    name: '7',
    subtitle: 'keine Anbindung',
    value: 7,
    image: undefined,
  },
]);

export const pipeTopViewHW = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_0.svg',
      import.meta.url
    ).href,
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_1.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_2.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_3.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_4.svg',
      import.meta.url
    ).href,
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_5.svg',
      import.meta.url
    ).href,
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_6.svg',
      import.meta.url
    ).href,
  },
  {
    name: '7',
    subtitle: '',
    value: 7,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_7.svg',
      import.meta.url
    ).href,
  },
  {
    name: '8',
    subtitle: '',
    value: 8,
    image: new URL(
      '@/assets/img/radiators/HW/pipeTopView/DSR_8.svg',
      import.meta.url
    ).href,
  },
  {
    name: '9',
    subtitle: 'weitere/keine',
    value: 9,
    image: undefined,
  },
]);

export const distributionChannelStructureHW = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_0.svg',
      import.meta.url
    ).href,
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_1.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_2.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_3.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_4.svg',
      import.meta.url
    ).href,
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_5.svg',
      import.meta.url
    ).href,
  },
  {
    name: '6',
    subtitle: 'kein VK',
    value: 6,
    image: undefined,
  },
  {
    name: '7',
    subtitle: '',
    value: 7,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_7.svg',
      import.meta.url
    ).href,
  },
  {
    name: '8',
    subtitle: '',
    value: 8,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelStructure/DCStructure_8.svg',
      import.meta.url
    ).href,
  },
  {
    name: '9',
    subtitle: 'weitere/andere',
    value: 9,
    image: undefined,
  },
]);

export const distributionChannelShapeHW = Object.freeze([
  {
    name: '0',
    subtitle: '',
    value: 0,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_0.svg',
      import.meta.url
    ).href,
  },
  {
    name: '1',
    subtitle: '',
    value: 1,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_1.svg',
      import.meta.url
    ).href,
  },
  {
    name: '2',
    subtitle: '',
    value: 2,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_2.svg',
      import.meta.url
    ).href,
  },
  {
    name: '3',
    subtitle: '',
    value: 3,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_3.svg',
      import.meta.url
    ).href,
  },
  {
    name: '4',
    subtitle: '',
    value: 4,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_4.svg',
      import.meta.url
    ).href,
  },
  {
    name: '5',
    subtitle: '',
    value: 5,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_5.svg',
      import.meta.url
    ).href,
  },
  {
    name: '6',
    subtitle: '',
    value: 6,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_6.svg',
      import.meta.url
    ).href,
  },
  {
    name: '7',
    subtitle: '',
    value: 7,
    image: new URL(
      '@/assets/img/radiators/HW/distributionChannelShape/DCShape_7.svg',
      import.meta.url
    ).href,
  },
  {
    name: '8',
    subtitle: 'unterschiedliche Querschnitte',
    value: 8,
    image: undefined,
  },
  {
    name: '9',
    subtitle: 'weitere/keine',
    value: 9,
    image: undefined,
  },
]);

export const connectionTypesHW = Object.freeze([
  {
    name: 'A',
    subtitle: 'Vorlauf oben, Rücklauf unten, einseitig',
    value: 'A',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_A1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'B',
    subtitle: 'Vorlauf oben, Rücklauf unten, wechselseitig',
    value: 'B',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_B1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'C',
    subtitle: 'Vorlauf und Rücklauf unten',
    value: 'C',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_C1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'C',
    subtitle: 'Vorlauf und Rücklauf unten mittig',
    value: 'C',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_C2.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'H',
    subtitle:
      'Vorlauf an einer Seite, Rücklauf unten, beides einseitig (Abstand der Anschlüsse 0-500 mm)',
    value: 'H',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_H1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'L',
    subtitle: 'Lanzenventil',
    value: 'L',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_L1.svg',
      import.meta.url
    ).href,
  },
  {
    name: 'U',
    subtitle:
      'Vorlauf an einer Seite, Rücklauf unten, beides einseitig (Abstand der Anschlüsse 0-500 mm)',
    value: 'U',
    image: new URL(
      '@/assets/img/radiators/common/connectionTypes/AA_U1.svg',
      import.meta.url
    ).href,
  },
]);
