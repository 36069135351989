import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { PlantDataClasses } from '@/models/plant/Plant';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';

export function usePlantStore() {
  return new PlantStore();
}

@Store
export class PlantStore extends miniEntityStore {
  private _plants: Map<string, PlantDataClasses>;

  constructor() {
    super();
    this._plants = new Map();
  }

  resetState() {
    this._plants = new Map();
  }

  get plants(): Map<string, any> {
    return this._plants;
  }

  addEntities(entities: PlantDataClasses[]): void {
    this._plants = new Map(
      entities.map(
        (entity) => [entity.id, entity] as [string, PlantDataClasses]
      )
    );
  }

  addEntity(newPlant: PlantDataClasses): void {
    this._plants.set(newPlant.id, newPlant);
  }

  deleteEntity(entity: PlantDataClasses) {
    this._plants.delete(<string>entity.id);
  }

  getAllPlantsInRoom(roomId: string): Array<PlantDataClasses> {
    return getAllEntitiesByCriteria(
      this._plants,
      (plant: PlantDataClasses) => plant.roomId === roomId
    );
  }
}
