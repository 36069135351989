<template>
  <div class="relative">
    <div class="flex flex-col gap-3" @submit.prevent="handleSubmit">
      <AddressOptionList v-model:address="building.address" :validation="v$" />
      <BaseSection heading="Gebäude Informationen">
        <BaseDropdown
          v-model:selection="building.usageType"
          label="Nutzungsart"
          dropdown-text="Nutzungsart wählen"
          class="md:basis-1/2"
          :options-list="usageTypeList"
          :validation="v$.building.usageType"
        />
        <div class="md:flex md:gap-4">
          <BaseDropdown
            v-model:selection="building.buildingSection"
            label="Lage"
            dropdown-text="Lage wählen"
            class="md:basis-1/2"
            :options-list="buildingSectionList"
            :validation="v$.building.buildingSection"
          />
          <BaseDropdown
            v-model:selection="building.entrance"
            label="Eingang"
            dropdown-text="Eingang wählen"
            class="md:basis-1/2"
            :options-list="entranceList"
            :validation="v$.building.entrance"
          />
        </div>
        <div class="md:flex md:gap-4">
          <BaseDropdown
            v-model:selection="building.interiorWallType"
            class="md:basis-1/2"
            label="Innenwände"
            dropdown-text="Innenwände wählen"
            :options-list="interiorWallOptions"
            :validation="v$.building.interiorWallType"
            :optional="!isOnSiteInspection || !isAccessible"
          />
          <BaseDropdown
            v-model:selection="building.exteriorWallType"
            class="md:basis-1/2"
            label="Außenwände"
            dropdown-text="Außenwände wählen"
            :options-list="exteriorWallOptions"
            :validation="v$.building.exteriorWallType"
            :optional="!isOnSiteInspection"
          />
        </div>
        <div class="md:flex md:gap-4">
          <BaseDropdown
            v-model:selection="building.floorType"
            class="md:basis-1/2"
            label="Fußboden"
            dropdown-text="Fußboden wählen"
            :options-list="floorOptions"
            :validation="v$.building.floorType"
            :optional="!isOnSiteInspection || !isAccessible"
          />
          <BaseFormInput
            v-model.number="building.constructionYear"
            label="Baujahr"
            class="md:basis-1/2"
            :validation="v$.building.constructionYear"
            :optional="!isOnSiteInspection"
            test-id="input_constructionYear"
            type="number"
          />
        </div>
        <BaseSection v-if="!isNewBuilding" heading="Wird vom System ermittelt">
          <div class="md:flex md:gap-4">
            <BaseFormInput
              v-model="buildingMetrics.floorLevels"
              disabled
              class="md:basis-1/3"
              label="Anzahl Stockwerke"
              :optional="false"
            />
            <BaseFormInput
              v-model="buildingMetrics.roomgroups"
              disabled
              class="md:basis-1/3"
              label="Anzahl angemeldeter Mieter"
            />
            <BaseFormInput
              v-model="buildingMetrics.average"
              disabled
              class="md:basis-1/3"
              label="Anzahl Wohnungen pro Geschoß"
            />
          </div>
        </BaseSection>
      </BaseSection>

      <BaseSection
        heading="Fotos (Außenansicht, Gebäude, Treppenhaus, Klingelschild, Fluchtpläne)"
      >
        <ImageCapture
          ref="imageCaptureRef"
          :object-id="building.id"
          :object-type="ImageObjectType.BUILDING"
          :capture-image-information="true"
          :image-keyword-list="buildingImageKeywordList"
        />
      </BaseSection>

      <BaseSection heading="Kontaktperson">
        <div class="md:flex md:gap-4">
          <div class="md:basis-1/2">
            <BaseFormInput
              v-model="building.contactPerson.name"
              label="Name"
              :optional="true"
              test-id="input_contactPersonName"
              :auto-correct="false"
            />
          </div>
          <div class="md:basis-1/2">
            <BaseFormInput
              v-model="building.contactPerson.phoneNumber"
              label="Telefonnummer"
              :optional="true"
              test-id="input_contactPersonPhoneNumber"
            />
          </div>
        </div>
        <BaseTextarea
          v-model:input-text="building.contactPerson.note"
          label="Notizen Kontaktperson"
          :optional="true"
          test-id="textarea_contactPersonNote"
        />
      </BaseSection>
      <BaseSection heading="Weitere Angaben" :required="false">
        <BaseTextarea
          v-model:input-text="building.note"
          label="Notizen"
          test-id="textarea_note"
        />
        <div class="mt-6">
          <BaseToggle
            v-model:is-checked="building.hasMonumentProtection"
            label="Denkmalschutz"
            test-id="toggle_hasMonumentProtection"
            :required="false"
          />
          <BaseToggle
            v-model:is-checked="building.isAccessible"
            label="Kein Zutritt"
            :is-inverted="true"
            test-id="toggle_isAccessible"
            :required="false"
            @is-toggled="handleIsAccessibleChange($event)"
          />
          <div v-if="!building.isAccessible" class="mt-3 gap-3 md:flex">
            <BaseDropdown
              v-model:selection="building.reasonNotAccessible"
              label="Grund"
              dropdown-text="Grund wählen"
              :options-list="buildingNotAccessibleReasonsList"
              :validation="v$.building.reasonNotAccessible"
              class="md:basis-4/5"
            />
            <BaseDateTime
              v-model:date="building.dateNotAccessible"
              :validation="v$.building.dateNotAccessible"
              class="md:basis-1/5"
            />
          </div>
        </div>
      </BaseSection>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  buildingImageKeywordList,
  buildingNotAccessibleReasonsList,
  buildingSectionList,
  entranceList,
  exteriorWallOptions,
  floorOptions,
  interiorWallOptions,
  usageTypeList,
} from '@/enums/building';
import { Building } from '@/models';
import { computed, onBeforeMount, Ref, ref } from 'vue';
import { useValidation } from '@/composables/useValidation';
import { useBuildingForm } from '@/composables/useBuildingForm';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import BaseToggle from '@/components/UI/Switch/BaseToggle.vue';
import BaseDateTime from '@/components/UI/Input/BaseDateTime.vue';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { FormAction, useFormStore } from '@/store/form/formStore';
import { useOrderStore } from '@/store/order/orderStore';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import ImageCapture, {
  ImageCaptureRef,
} from '@/components/Forms/DeviceFormComponents/ImageCapture.vue';
import { ImageObjectType } from '@/models/Image';
import { useFormChangeTrackerPlugin } from '@/composables/useFormChangeTracker';
import AddressOptionList from '@/components/Forms/BuildingFormComponents/AddressOptionList.vue';

const props = defineProps<{
  node?: Building;
  parentId: string;
}>();
const emit = defineEmits(['update-entity', 'update-entities']);

const imageCaptureRef: Ref<ImageCaptureRef | null> = ref(null);

const {
  building,
  initializeBuildingForm,
  handleIsAccessibleChange,
  handleSubmit,
} = useBuildingForm(props.node, props.parentId, imageCaptureRef, emit);

onBeforeMount(() => {
  initializeBuildingForm();
  useFormChangeTrackerPlugin(building);
});

const isNewBuilding = computed(() => {
  return useFormStore().formAction === FormAction.CREATE;
});

const buildingMetrics = computed(() => {
  return useBuildingStore().getBuildingMetrics();
});

const isOnSiteInspection = computed(() => {
  return useOrderStore().isOnSiteInspection();
});

const isAccessible = computed(() => {
  return building.value.isAccessible;
});

const { v$, isFormValid } = useValidation(
  { building },
  { isOnSiteInspection: isOnSiteInspection.value }
);

defineExpose({
  handleSubmit,
  isFormValid,
});
</script>
