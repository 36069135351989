<template>
  <div
    ref="sideMenu"
    class="h-full w-full overflow-y-auto p-5 pb-28 shadow md:p-10 md:px-10 md:pt-8"
  >
    <div class="flex flex-row justify-between">
      <div class="flex flex-col">
        <h4 class="mb-3 h-4 text-xl">
          <span v-if="selectedOrder.workTypeCode">{{
            selectedOrder?.workTypeCode
          }}</span>
        </h4>
        <h2>Auftragsübersicht</h2>
        <h4
          v-if="selectedOrder.orderId || selectedOrder.billingUnitReference"
          data-testid="activeOrderSubtitle"
          class="mb-5 text-sm font-normal"
        >
          <span class="font-bold">AE-NR.</span>
          {{ selectedOrder.billingUnitReference }}
          <span class="ml-3 font-bold">Auftragsnummer</span>
          {{ selectedOrder.orderId }}
        </h4>
      </div>
      <div class="flex md:hidden">
        <em
          class="mdi mdi-close cursor-pointer text-3xl"
          @click="emits('update:selectedOrder', undefined)"
        ></em>
      </div>
    </div>

    <div
      v-if="!isOrderClosed"
      class="fixed bottom-8 right-10 z-10 flex justify-between"
    >
      <ScrollButton
        button-text="Auftrag starten"
        alternative-button-text="Auftrag fortführen"
        :show-alternative-button-text="isOrderInProgress"
        button-icon="arrow-right-thick"
        data-testid="button-start-order"
        @on-click="goToOrder"
      />
    </div>

    <div class="grid grid-flow-row grid-cols-1 gap-5 md:grid-cols-2">
      <InfoCard icon="calendar-clock">
        <div class="flex-col">
          <div>{{ formattedDate }}</div>
          <div>{{ formattedStartAndEndTime }}</div>
        </div>
      </InfoCard>
      <InfoCard icon="warehouse">
        <div class="flex flex-col self-center">
          <div v-if="selectedOrder.locationReference">
            Leitzahl: {{ selectedOrder.locationReference }}
          </div>
          <div v-else>Keine Leitzahl angegeben</div>
        </div>
      </InfoCard>
      <InfoCard icon="phone" class="md:col-span-2">
        <div
          v-if="
            !selectedOrder.contactPerson?.name &&
            !selectedOrder.contactPerson?.phoneNumber
          "
          class="flex flex-col self-center text-neutral-400"
        >
          Keine Kontaktperson angegeben
        </div>
        <div
          v-else
          class="flex flex-col self-center"
          data-testid="orderContactPerson"
        >
          <div>
            <span class="font-bold">{{
              selectedOrder.contactPerson?.name
            }}</span>
            <span v-if="selectedOrder.contactPerson?.role"
              >,
              {{ getRoleDisplayName(selectedOrder.contactPerson?.role) }}</span
            >
            <span v-if="selectedOrder.contactPerson?.phoneNumber"
              >, Tel. {{ selectedOrder.contactPerson?.phoneNumber }}</span
            >
          </div>
        </div>
      </InfoCard>
    </div>

    <h3>Adressen</h3>
    <InfoCard>
      <div class="flex grow flex-col" data-testid="orderAddress">
        <div class="font-bold">Hauptadresse</div>
        <div>{{ primaryAddress }}</div>
      </div>

      <div
        class="flex cursor-pointer flex-col items-center justify-center"
        @click="openMaps"
      >
        <div
          class="flex h-8 w-8 items-center justify-center rounded-full bg-primary-1 text-white hover:bg-primary-2"
        >
          <em class="mdi mdi-navigation"></em>
        </div>
      </div>
    </InfoCard>
    <InfoCard v-if="hasAdditionalAddresses" class="mt-5 py-1">
      <table class="my-3 table-auto" aria-label="AdditionalAddresses">
        <tbody>
          <tr v-for="(address, key) in additionalAddresses" :key="key">
            <td>
              {{ address.street }} {{ address.houseNumber }},
              {{ address.zipCode }} {{ address.city }}
            </td>
          </tr>
        </tbody>
      </table>
    </InfoCard>
    <div v-if="!isInspectionOrder">
      <h3>Artikelliste</h3>
      <InfoCard class="py-1">
        <MaterialTable :material-list="selectedOrder.articles.items" />
      </InfoCard>
    </div>

    <h3>Notizen</h3>
    <InfoCard>
      <div
        v-if="selectedOrder.notes?.length > 0"
        class="flex-col whitespace-pre-line"
        data-testid="orderNotes"
      >
        {{ selectedOrder.notes }}
      </div>
      <div v-else>Notizen sind leer</div>
    </InfoCard>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, provide, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useOrderStore } from '@/store/order/orderStore';
import { Order, OrderType } from '@/models/Order';
import { OrderStatus } from '@/models/types/OrderStatus';
import MaterialTable from '@/components/OrderManagement/MaterialTable.vue';
import { getRoleDisplayName } from '@/models/types/ContactPersonRole';
import ScrollButton from '@/components/UI/Button/ScrollButton.vue';
import InfoCard from '@/components/UI/Card/InfoCard.vue';

const router = useRouter();

const emits = defineEmits(['update:selectedOrder']);

const props = defineProps<{
  selectedOrder: Order;
}>();

const isScrolled = ref(false);
provide('isScrolled', isScrolled);
const sideMenu = ref<HTMLElement | undefined>(undefined);

onMounted(() => {
  sideMenu?.value?.addEventListener('scroll', checkScroll);
});

const checkScroll = () => {
  isScrolled.value = (sideMenu?.value?.scrollTop as number) > 100;
};

const goToOrder = () => {
  const entitiesStore = useEntitiesStore();
  const orderStore = useOrderStore();
  const layoutStore = useLayoutStore();

  orderStore.startOrder(props.selectedOrder.id);

  entitiesStore
    .setActiveBusinessEntityById(props.selectedOrder.businessEntityId)
    .then(() => {
      router.push('/gebaeudeuebersicht');
      if (entitiesStore.activeBusinessEntity) {
        layoutStore.changeCurrentNode(entitiesStore.activeBusinessEntity);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

const isOrderInProgress = computed(() => {
  return props.selectedOrder.status === OrderStatus.IN_PROGRESS;
});

const isOrderClosed = computed(() => {
  return props.selectedOrder.status === OrderStatus.CLOSED;
});

const formattedDate = computed(() => {
  const date = props.selectedOrder.appointment.start;
  return new Date(date).toLocaleDateString('de-DE', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
});

const formattedStartAndEndTime = computed(() => {
  const startTime = new Date(
    props.selectedOrder.appointment.start
  ).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  });
  const endTime = new Date(
    props.selectedOrder.appointment.end
  ).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${startTime} - ${endTime} Uhr`;
});

const primaryAddress = computed(() => {
  return `${props.selectedOrder.primaryAddress.street} ${props.selectedOrder.primaryAddress.houseNumber}, ${props.selectedOrder.primaryAddress.zipCode} ${props.selectedOrder.primaryAddress.city}`;
});

const additionalAddresses = computed(() => {
  return props.selectedOrder.additionalAddresses;
});

const hasAdditionalAddresses = computed(() => {
  return props.selectedOrder.additionalAddresses
    ? props.selectedOrder.additionalAddresses.length > 0
    : false;
});

const isInspectionOrder = computed(() => {
  return props.selectedOrder.workTypeCode === OrderType.ON_SITE_INSPECTION;
});

let mapsUrl =
  'https://www.google.com/maps/dir/?api=1&destination=' + primaryAddress.value;

watch(
  () => primaryAddress.value,
  (value) => {
    mapsUrl = 'https://www.google.com/maps/dir/?api=1&destination=' + value;
  }
);
const openMaps = () => {
  window.open(mapsUrl, '_blank');
};
</script>
