<template>
  <div
    class="prose prose-neutral flex min-h-screen min-w-[100vw] items-center justify-center overflow-hidden bg-light-0 dark:bg-dark-0"
  >
    <div class="absolute h-full w-full">
      <img
        :src="backgroundURL"
        class="m-0 h-full w-full object-cover blur-sm brightness-75"
        alt="Logo"
      />
    </div>
    <div class="login z-10 flex flex-col items-center justify-center">
      <div class="logo-border">
        <div
          class="login-container flex w-full flex-col bg-light-1 px-8 py-20 sm:px-20"
        >
          <img :src="logoUrl" class="max h-24 justify-self-center" alt="Logo" />
          <component
            :is="currentFormComponent"
            @login="login()"
            @forget-password="setForgetPasswordForm()"
            @send-recovery-email="sendRecoveryEmail()"
            @go-to-login-form="setLoginForm()"
            @set-new-password="setNewPassword()"
            @validate-change-password="validateChangePassword()"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, provide, ref, shallowRef } from 'vue';

import { User } from '@/models/auth/User';

import { useRouter } from 'vue-router';
import { UserAuth } from '@/models/auth/UserAuth';
import { useUserStore } from '@/store/user/userStore';

import loginCover from '@/assets/img/login/login-bg.jpeg';
import logoUrl from '@/assets/logo_small_colored.svg';
import backgroundURL from '@/assets/img/login/login-bg.jpeg';

import { useValidation } from '@/composables/useValidation';
import { ANALYTICS_PROVIDER } from '@/plugins/bxAnalytics';
import ForgetPassword from '@/components/Login/ForgetPassword.vue';
import LoginForm from '@/components/Login/LoginForm.vue';
import ChangePassword from '@/components/Login/ChangePassword.vue';
import ResetPassword from '@/components/Login/ResetPassword.vue';

export default defineComponent({
  name: 'Login',
  setup() {
    const userStore = useUserStore();
    const router = useRouter();

    const userForms = {
      LOGIN_FORM: LoginForm,
      FORGET_PASSWORD: ForgetPassword,
      CHANGE_PASSWORD: ChangePassword,
      RESET_PASSWORD: ResetPassword,
    };

    const user = ref(new User());
    const { v$ } = useValidation({ user });

    const authenticationMessage = ref<UserAuth>(new UserAuth());

    const isLoading = ref<boolean>(false);

    const setAuthenticationMessage = (userAuth: UserAuth) => {
      authenticationMessage.value = userAuth;
      authenticationMessage.value.setMessage(userAuth.message);
    };

    const currentFormComponent = shallowRef(userForms.LOGIN_FORM);
    const setForgetPasswordForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.FORGET_PASSWORD;
    };
    const setLoginForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.LOGIN_FORM;
    };
    const setResetCodeForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.RESET_PASSWORD;
    };
    const setChangePasswordForm = () => {
      authenticationMessage.value.reset();
      currentFormComponent.value = userForms.CHANGE_PASSWORD;
    };

    provide('v$', v$);
    provide('user', user);
    provide('authenticationMessage', authenticationMessage);
    provide('isLoading', isLoading);

    const analytics = inject(ANALYTICS_PROVIDER);

    /*
     * Store dispatch actions
     */
    const login = () => {
      isLoading.value = true;
      userStore.login(user.value).then((authentication: UserAuth) => {
        isLoading.value = false;

        if (authentication.isPasswordChangeRequired()) {
          user.value.password = '';
          setChangePasswordForm();
          return;
        }
        if (authentication.isSuccessful()) {
          analytics?.identifyUser(user.value.username);
          router.push({ name: 'Home' });
        }
        setAuthenticationMessage(authentication);
      });
    };

    const sendRecoveryEmail = () => {
      isLoading.value = true;
      userStore.forgetPassword(user.value).then((response: UserAuth) => {
        isLoading.value = false;
        if (response.isSuccessful()) {
          setResetCodeForm();
        }
        setAuthenticationMessage(response);
      });
    };

    const setNewPassword = () => {
      isLoading.value = true;
      userStore.setNewPassword(user.value).then((response: UserAuth) => {
        isLoading.value = false;
        if (response.isSuccessful()) {
          setLoginForm();
        }
        setAuthenticationMessage(response);
      });
    };

    const validateChangePassword = () => {
      isLoading.value = true;
      userStore
        .changePasswordWithResetCode(user.value)
        .then((response: UserAuth) => {
          isLoading.value = false;
          if (response.isSuccessful()) {
            setLoginForm();
          }
          setAuthenticationMessage(response);
        });
    };

    return {
      login,
      v$,
      user,
      isLoading,
      currentFormComponent,
      authenticationMessage,
      validateChangePassword,
      setForgetPasswordForm,
      setLoginForm,
      sendRecoveryEmail,
      setNewPassword,
      loginCover,
      logoUrl,
      backgroundURL,
    };
  },
});
</script>

<style scoped lang="scss">
.login {
  width: 50%;
}

.login-container {
  border-radius: 25px;
}

.logo-border {
  width: 100%;
  max-width: 550px;
  border-radius: 30px;
  padding: 0.3rem;
  background: linear-gradient(
    45deg,
    rgba(70, 144, 222, 1) 0%,
    rgba(51, 200, 123, 1) 100%
  );
  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

::v-deep(.label) {
  font-size: 16px;
}

::v-deep(.back-button) {
  background: unset !important;
}

::v-deep(.is-login-button) {
  border-radius: 8px;
}

::v-deep(.action-button) {
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  padding: 20px 0;
}
</style>
