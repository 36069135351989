<template>
  <div class="mb-12 mt-24 md:mt-9">
    <div class="flex justify-between">
      <h1 class="mb-3">Deine Aufträge</h1>

      <div data-testId="refreshOrdersButton" @click="fetchOrders">
        <em
          class="mdi mdi-24px mdi-refresh cursor-pointer"
          :class="{ 'rotate-animation': isLoading }"
        ></em>
      </div>
    </div>
    <div v-if="lastUpdatedTimestamp" data-testId="orderListLastUpdated">
      Zuletzt aktualisiert am : {{ lastUpdatedTimestamp }}
    </div>
    <div v-if="Object.keys(groupedOpenOrders).length > 0">
      <div v-for="(orders, index) in groupedOpenOrders" :key="index">
        <h4>{{ index }}</h4>
        <div
          v-for="order in orders"
          :key="order.id"
          :data-testid="`orderCard_open_${order.orderId}`"
        >
          <OrderCard
            :is-selected="order.id === props.selectedOrder?.id"
            :order="order"
            @click="$emit('update:selectedOrder', order)"
          />
        </div>
      </div>
    </div>
    <h5 v-else class="rounded-xl bg-light-1 px-7 py-10 dark:bg-dark-1">
      Wir konnten keine offenen Aufträge für dich finden.<br />Bitte kontaktiere
      deinen Disponent:in.
    </h5>
    <div v-if="closedOrders.length > 0">
      <h3
        data-testid="closedOrdersSpoiler"
        :data-open="showClosedOrders"
        @click="toggleClosedOrders"
      >
        <em
          :class="{
            'mdi mdi-chevron-up': showClosedOrders,
            'mdi mdi-chevron-down': !showClosedOrders,
          }"
        />
        Abgeschlossene Aufträge
      </h3>
      <div v-if="showClosedOrders">
        <div
          v-for="order in closedOrders"
          :key="order.id"
          :data-testid="`orderCard_closed_${order.orderId}`"
        >
          <OrderCard
            :is-selected="order.id === props.selectedOrder?.id"
            :order="order"
            @click="$emit('update:selectedOrder', order)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useOrderStore } from '@/store/order/orderStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import OrderCard from '@/components/OrderManagement/OrderCard.vue';
import { Order } from '@/models/Order';
import { useAppStore } from '@/store/app/AppStore';

const emits = defineEmits(['update:selectedOrder']);

const props = defineProps<{
  selectedOrder?: Order;
}>();

const orderStore = useOrderStore();
const layoutStore = useLayoutStore();
const showClosedOrders = ref(false);
const toggleClosedOrders = () => {
  showClosedOrders.value = !showClosedOrders.value;
};

const groupedOpenOrders = computed(() => {
  return groupByDate([...orderStore.activeOrders, ...orderStore.pendingOrders]);
});

const groupByDate = (orders: Array<Order>) => {
  return orders.reduce((acc: Record<string, Array<Order>>, obj: Order) => {
    const date = new Date(obj.appointment.start).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: 'numeric',
    });
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(obj);
    return acc;
  }, {});
};

const closedOrders = computed(() => {
  return orderStore.closedOrders;
});

const isLoading = computed(() => {
  return layoutStore.isLoading;
});

const lastUpdatedTimestamp = ref<string>();

const fetchOrders = async () => {
  lastUpdatedTimestamp.value = new Date().toLocaleDateString('de-DE', {
    hour: 'numeric',
    minute: 'numeric',
  });

  layoutStore.setLoadingState(true);
  await getOrdersFromApi();
  if (!props.selectedOrder && Object.keys(groupedOpenOrders.value).length > 0) {
    emits(
      'update:selectedOrder',
      groupedOpenOrders.value[Object.keys(groupedOpenOrders.value)[0]][0]
    );
  }
  await useAppStore().cleanUpOldData();
  layoutStore.setLoadingState(false);
};

onMounted(() => {
  try {
    fetchOrders();
  } catch (e) {
    layoutStore.setLoadingState(false);
  }
});

const getOrdersFromApi = () => orderStore.fetchAllOrders();
</script>

<style scoped>
.rotate-animation:before {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
