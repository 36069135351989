import { Pinia, Store } from '@/store/pinia-class-component';
import posthog from 'posthog-js';
import { Preferences } from '@capacitor/preferences';
import { useOrderStore } from '@/store/order/orderStore';
import { LocalDb } from '@/native/plugins/LocalDb';

export function useAppStore() {
  return new AppStore();
}

@Store
class AppStore extends Pinia {
  private _isChangelogOpen = false;

  get isChangelogOpen(): boolean {
    return this._isChangelogOpen;
  }

  toggleChangelogDialog() {
    this._isChangelogOpen = !this._isChangelogOpen;
  }

  async closeChangelogDialog() {
    this._isChangelogOpen = false;
    await Preferences.set({
      key: UserSettingsKeys.HAS_DISMISSED_CHANGELOG,
      value: 'true',
    });
  }

  async shouldOpenChangelogDialog(): Promise<boolean> {
    if (!posthog.isFeatureEnabled('show-changelog-de')) return false;
    const setting = await this.getSetting(
      UserSettingsKeys.HAS_DISMISSED_CHANGELOG
    );
    return setting === 'false' || !setting;
  }

  async getSetting(key: string) {
    const setting = await Preferences.get({
      key,
    });
    return setting.value;
  }

  isLiveUpdateEnabled(): boolean {
    return <boolean>posthog.isFeatureEnabled('is-live-update-enabled');
  }

  getLiveUpdatePayload(): Record<string, any> {
    return posthog.getFeatureFlagPayload('is-live-update-enabled') as Record<
      string,
      any
    >;
  }

  async cleanUpOldData() {
    const lastCleanUpTimestamp = await Preferences.get({
      key: UserSettingsKeys.LAST_CLEAN_UP_KEY,
    });

    if (lastCleanUpTimestamp.value) {
      const lastCleanUpDate = new Date(lastCleanUpTimestamp.value);
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      if (lastCleanUpDate > oneMonthAgo) {
        console.debug(
          `Last cleanup was less than a month ago (${lastCleanUpDate}). Skipping cleanup.`
        );
        return;
      }
    }

    const orders = [
      ...useOrderStore().pendingOrders,
      ...useOrderStore().activeOrders,
    ];

    const currentBusinessEntityIds = orders.map(
      (order) => order.businessEntityId
    );
    const localBusinessEntityIds = await LocalDb.getAllBusinessEntityIds();

    for (const id of localBusinessEntityIds['businessEntityIds']) {
      if (currentBusinessEntityIds.includes(id)) continue;
      console.debug('Cleaning up old businessEntity', id);
      LocalDb.deleteBusinessEntity({ id });
      await Preferences.set({
        key: UserSettingsKeys.LAST_CLEAN_UP_KEY,
        value: new Date().toISOString(),
      });
    }
  }
}

export enum UserSettingsKeys {
  HAS_DISMISSED_CHANGELOG = 'hasDismissedChangelog',
  LAST_CLEAN_UP_KEY = 'lastCleanUp',
}
