<template>
  <FormStepper
    v-if="!showResults"
    :valid-step="1"
    header-teleport-target="#modal-header"
    wizard-title="Funkcheck"
    @on-tab-change="goToPreviousForm"
    @on-stepper-completion="showFunkcheckResults"
  >
    <StepperContent icon="mdi-plus"></StepperContent>

    <StepperContent icon="mdi-signal" :selected="true">
      <MobileSignalStrength />
    </StepperContent>
    <StepperContent icon="mdi-link">
      <QAppStep />
    </StepperContent>

    <StepperContent icon="mdi-cloud-arrow-up-outline">
      <SendReceiveStep />
    </StepperContent>

    <StepperContent icon="mdi-hammer">
      <GatewayInstallationStep />
    </StepperContent>

    <StepperContent icon="mdi-signal-variant">
      <ScanFunctionStep />
    </StepperContent>
  </FormStepper>

  <div v-if="showResults">
    <DeviceManagement />
    <div class="column is-6 save-container is-pulled-right">
      <button
        class="button is-wide is-primary"
        data-testid="funkcheck-restart"
        @click="restartFunkcheck"
      >
        Funkcheck neu starten
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import FormStepper from '@/components/UI/Stepper/FormStepper.vue';
import StepperContent from '@/components/UI/Stepper/StepperContent.vue';
import MobileSignalStrength from '@/components/Funkcheck/MobileSignalStrength.vue';
import QAppStep from '@/components/Funkcheck/QAppStep.vue';
import SendReceiveStep from '@/components/Funkcheck/SendReceiveStep.vue';
import GatewayInstallationStep from '@/components/Funkcheck/GatewayInstallationStep.vue';
import ScanFunctionStep from '@/components/Funkcheck/ScanFunctionStep.vue';
import DeviceManagement from '@/components/Funkcheck/DeviceManagement.vue';
import { defineComponent } from 'vue';
import { useFormStore } from '@/store/form/formStore';

export default defineComponent({
  name: 'FunkcheckForm',
  components: {
    QAppStep,
    FormStepper,
    StepperContent,
    SendReceiveStep,
    ScanFunctionStep,
    GatewayInstallationStep,
    DeviceManagement,
    MobileSignalStrength,
  },
  setup() {
    const formStore = useFormStore();

    return {
      formStore,
    };
  },
  data() {
    return {
      showResults: false,
    };
  },
  methods: {
    goToPreviousForm(index: number): void {
      if (index === 0) {
        this.formStore.setToLastForm();
      }
    },
    restartFunkcheck(): void {
      this.showResults = false;
    },
    showFunkcheckResults(): void {
      this.showResults = true;
    },
  },
});
</script>

<style lang="scss">
.content-box {
  @apply rounded-xl bg-light-3 p-5 dark:bg-dark-3;
}
</style>
