import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { Tag } from '@/utils/canvas/drawingUtils';
import {
  AdapterType,
  adapterTypeList,
  deviceTypeDetails,
  deviceTypes,
} from '@/enums/device';
import { DeviceClasses, DeviceType } from '@/models/devices/Device';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';

export function useInstallationPointStore() {
  return new InstallationPointStore();
}

@Store
export class InstallationPointStore extends miniEntityStore {
  private _installationPoints: Map<string, InstallationPoint>;
  recentAdapterList: Set<string>;

  constructor() {
    super();
    this._installationPoints = new Map();
    this.recentAdapterList = new Set();
  }

  get installationPoints(): Map<string, InstallationPoint> {
    return this._installationPoints;
  }

  get adaptersSortedByFrequency(): Map<string, number> {
    const adapterTypeMap: Map<string, number> = new Map();

    this._installationPoints.forEach((installationPoint) => {
      installationPoint.accessories.forEach((adapter) => {
        const currentFrequency: number =
          adapterTypeMap.get(adapter.customizedNumber) || 0;
        adapterTypeMap.set(adapter.customizedNumber, currentFrequency + 1);
      });
    });

    return new Map([...adapterTypeMap.entries()].sort((a, b) => b[1] - a[1]));
  }

  get recommendedAdaptersPerSession() {
    let combinedList: Array<string> = [];
    const maxNumberOfRecommendedAdapters = 3;
    const numberOfFrequencyAdapters = 2;

    const adapterFrequencyIterator = this.adaptersSortedByFrequency.keys();
    for (let i = 0; i < numberOfFrequencyAdapters; i++) {
      combinedList.push(adapterFrequencyIterator.next().value);
    }
    let startingIndex = numberOfFrequencyAdapters;
    const recentAdapterArray = Array.from(this.recentAdapterList);

    recentAdapterArray.reverse().forEach((item) => {
      if (
        !combinedList.includes(item) &&
        startingIndex <
          maxNumberOfRecommendedAdapters + numberOfFrequencyAdapters
      ) {
        combinedList[startingIndex] = item;
        startingIndex++;
      }
    });
    combinedList = combinedList.filter((a) => a !== undefined);
    combinedList = combinedList.slice(0, maxNumberOfRecommendedAdapters);

    const recommendedAdaptersList: Array<AdapterType> = [];

    combinedList.forEach((item) => {
      const adapter = adapterTypeList.find(
        (adapter) => adapter.customizedNumber === item
      );
      if (adapter) {
        recommendedAdaptersList.push(adapter);
      }
    });

    return recommendedAdaptersList;
  }

  addToRecentAdapterList(adapter: AdapterType) {
    const { customizedNumber } = adapter;

    if (this.recentAdapterList.has(customizedNumber)) {
      this.recentAdapterList.delete(customizedNumber);
    }
    this.recentAdapterList.add(customizedNumber);
  }

  getAllActiveDevices() {
    const activeDeviceIds = new Set<string>();
    this._installationPoints.forEach((installationPoint) => {
      if (installationPoint.activeDeviceId) {
        activeDeviceIds.add(installationPoint.activeDeviceId);
      }
    });
    const devices: DeviceClasses[] = [];
    useDeviceStore()
      .getDevices()
      .forEach((device) => {
        if (activeDeviceIds.has(device.id)) {
          devices.push(device);
        }
      });
    return devices;
  }

  getCentralWarmWaterHeatMeter() {
    const centralWarmWaterHeatMeters: DeviceClasses[] = [];
    getAllEntitiesByCriteria(
      this._installationPoints,
      (entity: InstallationPoint) => entity.isCentralAdvancedRegistrationMeter
    ).forEach((installationPoint) => {
      const device = useDeviceStore().devices.get(
        installationPoint.activeDeviceId
      );
      if (!device) {
        return;
      }
      centralWarmWaterHeatMeters.push(device);
    });
    return centralWarmWaterHeatMeters;
  }

  getInstallationPointsByParentId(parentId: string): InstallationPoint[] {
    return getAllEntitiesByCriteria(
      this._installationPoints,
      (entity: InstallationPoint) => entity.getParentId() === parentId
    );
  }

  getInstallationPointsByRadiatorId(radiatorId: string): InstallationPoint[] {
    return getAllEntitiesByCriteria(
      this._installationPoints,
      (entity: InstallationPoint) => entity.radiatorId === radiatorId
    );
  }

  getInstallationPointsOnPlant(plantId: string): InstallationPoint[] {
    return getAllEntitiesByCriteria(
      this._installationPoints,
      (entity: InstallationPoint) => entity.plantId === plantId
    );
  }

  getAllInstallationPointsByArmId(armId: string): InstallationPoint[] {
    return getAllEntitiesByCriteria(
      this._installationPoints,
      (entity: InstallationPoint) => entity.advancedRegistrationById === armId
    );
  }

  getInstallationPointsByRoomGroupId(parentId: string): InstallationPoint[] {
    return getAllEntitiesByCriteria(
      this._installationPoints,
      (entity: InstallationPoint) => entity.billedInRoomGroupId === parentId
    );
  }

  getInstallationPointsByRoomId(parentId: string): InstallationPoint[] {
    const radiatorIds = useRadiatorStore()
      .getAllRadiatorsInRoom(parentId)
      .map((radiator) => radiator.id);
    const allParentIds = [...radiatorIds, parentId];
    return getAllEntitiesByCriteria(
      this._installationPoints,
      (entity: InstallationPoint) => allParentIds.includes(entity.getParentId())
    );
  }

  getFailedInstallationAttempt(roomId: string) {
    return this.getInstallationPointsByRoomId(roomId).filter(
      (ip) => !ip.activeDeviceId && ip.failedInstallationAttempt
    );
  }

  isInstallationPointDeletable(id: string) {
    const installationPoint = this._installationPoints.get(id);
    if (!installationPoint) {
      throw new Error('Installation point not found');
    }
    if (installationPoint.failedInstallationAttempt) {
      return true;
    }
    const devices = useDeviceStore().getDevicesByInstallationPointId(id);
    return devices.length <= 1;
  }

  addEntities(entities: InstallationPoint[]): void {
    this._installationPoints = new Map(
      entities.map((entity) => {
        return [entity.id, entity] as [string, InstallationPoint];
      })
    );
  }

  addEntity(installationPoint: InstallationPoint): void {
    this._installationPoints.set(installationPoint.id, installationPoint);
  }

  getNewRoomOrdinal(parentId: string | undefined): number {
    if (parentId === undefined) {
      return 0;
    }
    const children = getAllEntitiesByCriteria(
      this._installationPoints,
      (installationPoint: InstallationPoint) =>
        installationPoint.getParentId() === parentId
    );
    return children.length + 1;
  }

  deleteEntity(entity: InstallationPoint): void {
    const devices = useDeviceStore().getDevicesByInstallationPointId(entity.id);
    devices.forEach((device) => {
      useDeviceStore().deleteEntity(device);
    });
    this._installationPoints.delete(entity.id);
    this.updateOrdinals(entity.getParentId());
  }

  updateOrdinals(parentId: string): void {
    const installationPoints = this.getInstallationPointsByRadiatorId(parentId);
    installationPoints.sort((a, b) => a.ordinal - b.ordinal);
    installationPoints.forEach((installationPoint, index) => {
      installationPoint.ordinal = index;
    });
  }

  getTagsOfRoomgroup(roomgroupId: string): Record<string, Array<Tag>> {
    const installationPoints =
      this.getInstallationPointsByRoomGroupId(roomgroupId);

    if (installationPoints.length === 0) {
      return { ACTIVE: [], INACTIVE: [] };
    }

    return Object.values(deviceTypes).reduce(
      (tags, deviceType) => {
        const deviceDetail = deviceTypeDetails[deviceType as DeviceType];

        const label = deviceDetail?.shortLabel;
        const color = deviceDetail?.color ?? '#000000';

        const supportingInstallationPoints = installationPoints.filter(
          (ip) => ip.supportedDeviceType === deviceType
        );
        const activeInstallationPoints = supportingInstallationPoints.filter(
          (ip) => ip.activeDeviceId
        );
        const notActiveInstallationPoints = supportingInstallationPoints.filter(
          (ip) => !ip.activeDeviceId && ip.failedInstallationAttempt
        );

        if (activeInstallationPoints.length > 0) {
          const tag = new Tag(
            `${label}`,
            activeInstallationPoints.length,
            color
          );
          tags['ACTIVE'].push(tag);
        }

        if (notActiveInstallationPoints.length > 0) {
          const tag = new Tag(
            `${label} `,
            notActiveInstallationPoints.length,
            color
          );
          tags['INACTIVE'].push(tag);
        }

        return tags;
      },
      { ACTIVE: [], INACTIVE: [] } as Record<string, Array<Tag>>
    );
  }

  resetState(): void {
    this._installationPoints = new Map();
  }
}
