<template>
  <div class="flex flex-col gap-5">
    <div class="text-xl font-bold">Empfangsstärke installierte Geräte</div>
    <div>Mit dem Q-Tool wird die Position des DMGW im Haus simuliert.</div>
    <div class="content-box">
      <div class="flex justify-center">
        <a
          class="extern-link is-size-5"
          data-testid="qAppLink"
          @click="launchQToolProcess()"
        >
          Jetzt Q-tool öffnen
          <em class="mdi mdi-shape-square-plus ml-1"></em>
        </a>
      </div>

      <DottedList :points="points" />
    </div>
    <div v-if="showCheckbox" class="content-box">
      <BaseCheckbox
        v-model="isChecked"
        data-testid="receptionStrengthChecked"
        label="Empfangsstärke der installierten Geräte mit der Q-App geprüft"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { inject, ref, defineComponent, onMounted } from 'vue';
import BaseCheckbox from '@/components/UI/Checkbox/BaseCheckbox.vue';
import DottedList from '@/components/UI/SimpleList/DottedList.vue';
import { buildPLTFromDeviceList } from '@/helpers/qappPLTConverter';
import { default as envConfig } from '@/config/runtimeConfig';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FilterListItem, WebEvents } from '@/native/plugins/WebEvents';

export default defineComponent({
  name: 'QAppStep',
  components: { DottedList, BaseCheckbox },
  setup() {
    const entitiesStore = useEntitiesStore();
    const qAppLink = envConfig.qAppDeeplinkUrl;
    const funkcheckStore = useFunkcheckStore();

    /**
     * Functions provided by
     * {@link FormStepper#validateStep}
     */
    const validateStep = inject('validateStep') as (valid: boolean) => void;

    const points = [
      'Verbinden mit dem Qtool ',
      'Empfangsstärke muss mind. 50 betragen',
    ];
    const isChecked = ref<boolean>(false);
    const showCheckbox = ref<boolean>(false);
    const pltFile = ref<any>({
      filename: 'installation.plt',
      content: '...',
      saved: false,
    });

    onMounted(() => {
      generatePLT();
    });

    const generatePLT = () => {
      const deviceList = funkcheckStore.getFilterList;
      pltFile.value.content = buildPLTFromDeviceList(deviceList);
      const businessEntity = entitiesStore.activeBusinessEntity;
      const dateNow = new Date().toISOString().slice(0, 10).replace(/-/g, '');
      pltFile.value.filename = `${dateNow}-${businessEntity?.humanReadableId}.plt`;
    };

    const writePLTFileToSystemFile = async () => {
      await Filesystem.writeFile({
        path: `${pltFile.value.filename}`,
        data: pltFile.value.content,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      })
        .then(() => {
          console.debug('PLT File saved to disk');
          pltFile.value.saved = true;
        })
        .catch(() => {
          pltFile.value.saved = false;
        });
    };

    const launchQToolProcess = () => {
      showCheckbox.value = true;
      if (entitiesStore.activeBusinessEntity?.id === undefined) {
        return;
      }

      const filterList: Array<FilterListItem> = funkcheckStore.getFilterList;
      WebEvents.onStartQToolProcess({ filterList: filterList });
    };

    return {
      pltFile,
      validateStep,
      points,
      isChecked,
      showCheckbox,
      qAppLink,
      launchQToolProcess,
      writePLTFileToSystemFile,
    };
  },
  watch: {
    isChecked(newState: boolean): void {
      this.validateStep(newState);
    },
  },
});
</script>

<style lang="scss" scoped>
.extern-link {
  @apply text-primary-2;
  font-weight: bold;
}
</style>
