import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { InspectionDevice } from '@/models/inspectionPoint/InspectionDevice';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { deviceTypes } from '@/enums/device';
import { useOrderStore } from '@/store/order/orderStore';

export function useInspectionDeviceStore() {
  return new InspectionDeviceStore();
}

@Store
export class InspectionDeviceStore extends miniEntityStore {
  private _inspectionDevices: Map<string, InspectionDevice>;

  constructor() {
    super();
    this._inspectionDevices = new Map();
  }

  get inspectionDevices(): Map<string, InspectionDevice> {
    return this._inspectionDevices;
  }

  addEntity(inspectionDevice: InspectionDevice): void {
    this._inspectionDevices.set(<string>inspectionDevice.id, inspectionDevice);
  }

  addEntities(entities: InspectionDevice[]): void {
    this._inspectionDevices = new Map(
      entities.map((entity) => [entity.id, entity] as [string, InspectionDevice])
    );
  }

  deleteEntity(entity: InspectionDevice): void {
    this._inspectionDevices.delete(entity.id);
  }

  resetState() {
    this._inspectionDevices = new Map();
  }

  getDevicesByInspectionPointId(
    inspectionPointId: string
  ): Array<InspectionDevice> {
    return getAllEntitiesByCriteria(
      this._inspectionDevices,
      (entity: InspectionDevice) =>
        entity.inspectionPointId === inspectionPointId
    );
  }

  getAllActiveSRTs(): InspectionDevice[] {
    return getAllEntitiesByCriteria(
      this._inspectionDevices,
      (entity: InspectionDevice) =>
        useOrderStore().getOrderChanges()?.transactions[entity.id] &&
        entity.deviceType === deviceTypes.SmartRadiatorThermostat
    );
  }

  getAllActiveGateways(): InspectionDevice[] {
    return getAllEntitiesByCriteria(
      this._inspectionDevices,
      (entity: InspectionDevice) =>
        useOrderStore().getOrderChanges()?.transactions[entity.id] &&
        entity.deviceType === deviceTypes.LoRaWanGateway
    );
  }
}
